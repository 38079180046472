import {createContext, useContext, useEffect, useState} from "react";
import {axiosInstance} from "../utils/axios";
import {useNavigate, useSearchParams} from "react-router-dom";
import texts, {IText} from "../translations/auth";
import {useTranslation} from "./TranslationContext";
import {getUser, resetUser, setUser} from "../utils/localStorage";
import {Url} from "../utils/url";
import {Nullable} from "../utils/nullable";
import {useToastContext} from "../contexts/ToastContext"
import UserController from "../api/controllers/user";
import {IUser} from "../api/models/user"

const AuthContext = createContext(undefined as any);

type ProviderAuthT = {
    login: (email: string, password: string) => Promise<IUser | null>;
    logout: () => Promise<void>;
    sendResetPasswordRequest: (email: string) => Promise<void>;
    sendResetPasswordCode: (email: string) => Promise<boolean>;
    isLoggedIn: () => boolean;
    resetPassword: (email: string, password: string, token: string) => Promise<void>;
    currentUser: User | undefined;
    setCurrentUser: (user: User) => void;
    setUser: (user: User) => void;
}

export type User = Nullable<IUser>;

export const useAuth = (): ProviderAuthT => {
    return useContext(AuthContext);
}

interface IProps {
    children: JSX.Element[] | JSX.Element;
}

const AuthProvider = ({children}: IProps) => {
    const [currentUser, setCurrentUser] = useState<User>(getUser() as User);
    const navigate = useNavigate();
    const language = useTranslation().currentLanguage;
    const toastContext = useToastContext()

    useEffect(() => {
        const pathAfterLogin = window.location.pathname + window.location.search;

        if (!currentUser) {
            if (!['/login', '/register', '/reset-password', '/verify-email'].some((path) => pathAfterLogin.startsWith(path))) {
                navigate(`/login?redirect=${pathAfterLogin}`);
            }
        } else {
            if (currentUser.isSuperAdmin && pathAfterLogin.includes("/manage")) {
                navigate("/manage");
            } else if (pathAfterLogin === '/login') {
                navigate('/');
            } else {
                navigate(pathAfterLogin);
            }
        }
    }, [currentUser, navigate]);

    const login = async (email: string, password: string): Promise<IUser | null> => {
        return await UserController.login(email, password).then((res: any) => {
            if (res?.data?.success) {
                const user = {
                    _id: res.data.response._id,
                    firstName: res.data.response.firstName,
                    lastName: res.data.response.lastName,
                    email: res.data.response.email,
                    concern: res.data.response.concern,
                    userValidated: res.data.response.userValidated,
                    isAdmin: res.data.response.isAdmin,
                    isSuperAdmin: res.data.response.isSuperAdmin,
                    isEmailValid: res.data.response.isEmailValid,
                    emailValidationToken: res.data.response.emailValidationToken,
                    resetPasswordToken: res.data.response.resetPasswordToken,
                } as User;

                setUser(user );
                setCurrentUser(user);
                return user;
            }
            return null;
        }).catch((err: any) => {console.error(err)});
    }

    const sendResetPasswordCode = async (email: string): Promise<boolean> => {
        try {
            const res = await axiosInstance.post("/auth/check-reset-password", {
                "email": email
            }, {
                headers: {
                    Authorization: `Bearer ${getUser()?.accessToken}`
                }
            });
            if (res.status === 200)
                return true;
            return false
        } catch (err) {
            toastContext.toastUpdate({
                render: (texts.get(language) as IText).anErrorOccurred,
                type: "error",
                isLoading: false,
                autoClose: 2500
            })
            return false
        }
    }

    const sendResetPasswordRequest = async (email: string) => {
        try {
            const res = await axiosInstance.post("/auth/forgot-password", {
                "email": email
            });
            if (res.status !== 200)
                toastContext.toastUpdate({
                    render: (texts.get(language) as IText).anErrorOccurred,
                    type: "error",
                    isLoading: false,
                    autoClose: 2500
                })
            else
                toastContext.toastUpdate({
                    render: (texts.get(language) as IText).resetPasswordEmailSent,
                    type: "success",
                    isLoading: false,
                    autoClose: 2500
                })
        } catch (err) {
            toastContext.toastUpdate({
                render: (texts.get(language) as IText).anErrorOccurred,
                type: "error",
                isLoading: false,
                autoClose: 2500
            })
        }
    }

    const logout = async () => {
        setCurrentUser(null);
        resetUser();
        navigate("/login");
    }

    const isLoggedIn = () => {
        return currentUser !== null;
    }

    const resetPassword = async (email: string, password: string, token: string): Promise<void> => {
        try {
            const res = await axiosInstance.post("/auth/reset-password", {
                "email": email,
                "newPassword": password,
                "token": token
            });
            if (res.status === 200) {
                toastContext.toastUpdate({
                    render: (texts.get(language) as IText).passwordChanged,
                    type: "success",
                    isLoading: false,
                    autoClose: 2500
                })
                logout()
            } else
                toastContext.toastUpdate({
                    render: (texts.get(language) as IText).anErrorOccurred,
                    type: "error",
                    isLoading: false,
                    autoClose: 2500
                })
        } catch (err) {
            toastContext.toastUpdate({
                render: (texts.get(language) as IText).anErrorOccurred,
                type: "error",
                isLoading: false,
                autoClose: 2500
            })
        }
    }

    const value: ProviderAuthT = {
        login,
        logout,
        isLoggedIn,
        sendResetPasswordRequest,
        sendResetPasswordCode,
        resetPassword,
        currentUser,
        setCurrentUser,
        setUser
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
