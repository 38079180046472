import "../../css/constants.css";
import {Flex} from "rebass";
import MyText from "../../components/basics/MyText";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "../../contexts/TranslationContext";
import PageTitle from "../../components/PageTitle";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import {Loading} from "../../components/Loading";
import {useWindowDimensions} from "../../contexts/WindowDimensionsContext";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure} from "@nextui-org/react";
import { Dropzone } from "../../components/dropzone";
import axios from "axios";
import ImageViewer from "react-simple-image-viewer";
import {scroller} from "react-scroll";
import {
    CloseOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    CopyOutlined,
    EyeOutlined, DownloadOutlined,
    BackwardOutlined
} from '@ant-design/icons';
import {Input, Select, Button, Upload, Typography, Divider, Popconfirm, message} from "antd";
import {Gallery} from "react-grid-gallery";
const { TextArea } = Input;
const { Dragger } = Upload;

export default function Media() {
    const language = useTranslation().currentLanguage;
    const navigate = useNavigate()
    const authContext = useAuth();
    const windowDimensions = useWindowDimensions();
    const [mediasFolders, setMediasFolders] = useState([]);
    const [selectedMediaFolder, setSelectedMediaFolder] = useState({_id: "", title: "", concern: [], content: "", url: [], files: ""});
    const [filter, setFilter] = useState("")
    const [isPictureViewerOpen, setIsPictureViewerOpen] = useState(false);
    const [pictureViewerPicture, setPictureViewerPicture] = useState("");
    const [loading, setLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [fileList, setFileList] = useState([]);
    const [elementId, setElementId] = useState(null);
    const [newElement, setNewElement] = useState({_id: "", title: "", concern: [], content: "", url: [], files: ""});
    const [numberOfLinks, setNumberOfLinks] = useState( 0);
    const [highlighted, setHighlighted] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const idToSearch = searchParams.get("id");

    useEffect(() => {
        fetchElements()
    }, [])

    useEffect(() => {
        setSelectedMediaFolder({_id: "", title: "", concern: [], content: "", url: [], files: ""})
    }, [filter])

    useEffect(() => {
        if (idToSearch) {
            const highlightedElement = mediasFolders.find(element => element._id === idToSearch);
            if (highlightedElement) {
                setHighlighted(idToSearch);
                scroller.scrollTo(idToSearch, {
                    duration: 200,
                    smooth: true,
                    offset: -100,
                });
                setSearchParams({});
            }
        }
    }, [idToSearch, mediasFolders]);

    const fetchElements = async () => {
        setLoading(true);
        try {
            const response = await axios.get("http://localhost:3001/api/media/");
            if (response.status === 200) {
                const mediaItems = response.data.response || [];

                const updatedMediaItems = mediaItems.map((item) => {
                    const filesUrls = Array.isArray(item.files)
                        ? item.files.map((filePath) =>
                            `http://localhost:3001/${filePath.replace('/root/apps/intrakimmel/dest/mern/server/', '')}`
                        )
                        : [];

                    return { ...item, files: filesUrls };
                });

                setMediasFolders(
                    updatedMediaItems.sort((a, b) => {
                        const dateA = new Date(a.updatedAt || 0).getTime();
                        const dateB = new Date(b.updatedAt || 0).getTime();
                        return dateB - dateA;
                    })
                );
                
            } else {
                setMediasFolders([]);
            }
        } catch (error) {
            setMediasFolders([]);
            console.error('Error fetching media:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleElementSubmit = async (event) => {
        event?.preventDefault();
        const formData = new FormData();

        Object.keys(newElement).forEach((key) => {
            if (Array.isArray(newElement[key])) {
                if (key === "url") {
                    formData.append(key, JSON.stringify(newElement[key]));
                } else {
                    newElement[key].forEach((item) => formData.append(key, item));
                }
            } else {
                formData.append(key, newElement[key]);
            }
        });

        if (fileList && fileList.length > 0) {
            fileList.forEach(file => formData.append("files", file));
        }

        setLoading(true);
        try {
            if (elementId) {
                await axios.put(`http://localhost:3001/api/media/id/${elementId}`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
            } else {
                await axios.post(`http://localhost:3001/api/media/`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
            }
            resetForm();
            fetchElements()
        } catch (error) {
            console.error("Error submitting media:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleElementDelete = async () => {
        try {
            await axios.delete(`http://localhost:3001/api/media/id/${elementId}`);
            resetForm();
            fetchElements()
        } catch (error) {
            console.error("Error deleting media:", error);
        }
    }

    const resetForm = () => {
        setElementId(null);
        setNewElement({
            _id: "",
            title: "",
            concern: [],
            content: "",
            url: [],
            files: ""
        });
        setNumberOfLinks(0)
        setFileList([]);
    };

    function formatTimeDifference(timestampRaw) {
        const timestamp = new Date(timestampRaw);
        const now = new Date();
        const timeDifference = now.getTime() - timestamp.getTime();
        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        if (days > 0) {
            return `${days} jour${days === 1 ? "" : "s"}`;
        } else if (hours > 0) {
            return `${hours} heure${hours === 1 ? "" : "s"}`;
        } else {
            return `${minutes} minute${minutes === 1 ? "" : "s"}`;
        }
    }

    const handleTitleChange = (index, value) => {
        setNewElement(prevElement => {
            const updatedUrls = [...prevElement.url];

            if (index < updatedUrls.length) {
                updatedUrls[index].title = value;
            } else {
                updatedUrls.push({ title: value, link: "" });
            }

            return {
                ...prevElement,
                url: updatedUrls,
            };
        });
    };

    const handleLinkChange = (index, value) => {
        setNewElement(prevElement => {
            const updatedUrls = [...prevElement.url];

            if (index < updatedUrls.length) {
                updatedUrls[index].link = value;
            } else {
                updatedUrls.push({ title: "", link: value });
            }

            return {
                ...prevElement,
                url: updatedUrls,
            };
        });
    };

    const handleRemoveLink = (indexToRemove) => {
        setNumberOfLinks(prevNumberOfLinks => prevNumberOfLinks - 1);

        setNewElement(prevElement => {
            const updatedUrls = prevElement.url.filter((_, index) => index !== indexToRemove);

            return {
                ...prevElement,
                url: updatedUrls,
            };
        });
    };

    const handleFileChange = (newFileList) => {
        setFileList(newFileList);
    };

    const handleDownload = async (file, filename) => {
        try {
            const response = await fetch(file, {
                method: 'GET',
                headers: {
                }
            });

            if (!response.ok) {
                throw new Error("Failed to download file");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    const checkElementType = (element) => {
        const modifiedFileName = element
            .split('-')
            .slice(1)
            .join('-')

        if (/\.(jpg|jpeg|png|gif|bmp|svg|pdf)$/i.test(modifiedFileName)) {
            return "picture"
        } else if (/\.(mp4|avi|mov|mkv|webm)$/i.test(modifiedFileName)) {
            return "video"
        } else {
            return "file"
        }
        return "file"
    }

    const MyGallery = (props) => {
        const imageRef = useRef(null);
        const textRef = useRef(null);
        const [thisHighlighted, setThisHighlighted] = useState(false);

        useEffect(() => {
            if (highlighted === props.data._id) {
                setThisHighlighted(true);
                const timeoutId = setTimeout(() => {
                    setThisHighlighted(false);
                    setHighlighted("")
                }, 4750)
            }
        }, []);

        useEffect(() => {
            const setPadding = () => {
                if (imageRef.current && textRef.current) {
                    const imageWidth = imageRef.current.clientWidth;
                    if (imageWidth <= 200) {
                        textRef.current.style.paddingLeft = "25px";
                        textRef.current.style.paddingTop = "";
                    } else {
                        textRef.current.style.paddingLeft = "";
                        textRef.current.style.paddingTop = "30px";
                    }
                }
            };
            setPadding();
            window.addEventListener("resize", setPadding);
            return () => {
                window.removeEventListener("resize", setPadding);
            };
        }, []);

        return (
            <Card style={{ height: "100%" }} className={`identifiable-element ${thisHighlighted ? 'highlighted' : ''} hover:bg-[#fafafa] cursor-pointer`}>
                <Card.Header className={"py-3"}>
                    <Card.Title className={"m-0"} style={{ fontSize: "14px" }}><span className={"mr-1 text-xs text-gray-400"}>{"#" + (props.index + 1)}</span>{props.data.files[props.index].split('-').slice(1).join('-').slice(0, 30) + (props.data.files[props.index].split('-').slice(1).join('-').length > 30 ? '...' : '')}</Card.Title>
                </Card.Header>

                <div className="p-4" style={{ height: "100%" }}>
                    <Flex className="flex-col h-100">
                        {props.data.files && (
                            <Flex>
                                <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                    {checkElementType(props.data.files[props.index]) === "picture" && <Card.Img ref={imageRef} onClick={() => {setPictureViewerPicture(props.element); setIsPictureViewerOpen(true);}} variant="top" src={props.element} className={"border shadow-md"} style={{objectFit: "cover", borderRadius: "5px", cursor: "pointer", maxHeight: "200px", aspectRatio: "16 / 9", objectPosition: "top"}}/>}
                                    {checkElementType(props.data.files[props.index]) === "video" && <video className={"border shadow-md"} style={{objectFit: "cover", borderRadius: "5px", cursor: "pointer", maxHeight: "200px", aspectRatio: "16 / 9", objectPosition: "top"}}><source src={props.element} /></video>}
                                </div>
                            </Flex>
                        )}
                    </Flex>
                </div>

                <Card.Footer className="mt-auto py-3">
                    <Flex flexDirection="column" justifyContent="space-between" flexWrap="wrap">
                        <Flex width={"100%"} style={{columnGap: "10px"}} flexWrap={"wrap"} justifyContent={"center"} className={"my-2"}>
                            <Button icon={<EyeOutlined/>} className={"mb-2"} style={{width: "150px"}} onClick={() => {setPictureViewerPicture(props.element); setIsPictureViewerOpen(true)}}>Coup d'oeil</Button>
                            <Button icon={<DownloadOutlined/>} style={{width: "150px"}} onClick={() => handleDownload(props.element, props.data.files[props.index].split('-').slice(1).join('-'))}>Télécharger</Button>
                        </Flex>
                        <Flex width={"100%"} style={{columnGap: "10px"}} flexWrap={"wrap"} justifyContent={"center"}>
                            {authContext.currentUser?.isAdmin &&
                                <Button icon={<CopyOutlined/>} style={{width: "150px"}} className={"mb-2"} onMouseDown={(e) => {e.preventDefault();}} onBlur={(e) => e.preventDefault()} onClick={() => {navigator.clipboard.writeText("/?id="+props.data._id).then(() => {message.success("Le lien à été copié" + " ("+props.data.title+")")}).catch((err) => {console.error(err)})}}>
                                    Copier le lien
                                </Button>
                            }
                            {authContext.currentUser?.isAdmin && (
                                <Button icon={<EditOutlined />} style={{ width: "150px" }} className="mb-2" onClick={() => {setNewElement({ ...props.data, url: props.data.url || [] });setNumberOfLinks(props.data.url ? props.data.url.length : 0);setElementId(props.data._id);setFileList(props.data.files);onOpen();}}>
                                    Modifier
                                </Button>
                            )}
                        </Flex>
                    </Flex>
                </Card.Footer>
            </Card>

        )
    }

    const MyCard = (props) => {
        const imageRef = useRef(null);
        const textRef = useRef(null);
        const [thisHighlighted, setThisHighlighted] = useState(false);
        const [order, setOrder] = useState(1);

        const Image1 = () => {
            return (
                <div className="stacked-image-wrapper">
                    <img
                        className="stacked-image-item"
                        src={props.data.files[0]}
                        height={230}
                        width={"60%"}
                        style={{ transform: "rotate(15deg)", aspectRatio: "16/9", objectFit: "cover", objectPosition: "top" }}
                    />
                </div>
            );
        };
        const Image2 = () => {
            return (
                <div className="stacked-image-wrapper">
                    <img
                        className="stacked-image-item"
                        src={props.data.files[1]}
                        height={230}
                        width={"60%"}
                        style={{ transform: "rotate(3deg)", aspectRatio: "16/9", objectFit: "cover", objectPosition: "top" }}
                    />
                </div>
            );
        };
        const Image3 = () => {
            return (
                <div className="stacked-image-wrapper">
                    <img
                        className="stacked-image-item"
                        src={props.data.files[2]}
                        height={230}
                        width={"60%"}
                        style={{ transform: "rotate(-10deg)", aspectRatio: "16/9", objectFit: "cover", objectPosition: "top" }}
                    />
                </div>
            );
        };

        useEffect(() => {
            if (highlighted === props.data._id) {
                setThisHighlighted(true);
                const timeoutId = setTimeout(() => {
                    setThisHighlighted(false);
                    setHighlighted("")
                }, 4750)
            }
        }, []);

        useEffect(() => {
            const setPadding = () => {
                if (imageRef.current && textRef.current) {
                    const imageWidth = imageRef.current.clientWidth;
                    if (imageWidth <= 200) {
                        textRef.current.style.paddingLeft = "25px";
                        textRef.current.style.paddingTop = "";
                    } else {
                        textRef.current.style.paddingLeft = "";
                        textRef.current.style.paddingTop = "30px";
                    }
                }
            };
            setPadding();
            window.addEventListener("resize", setPadding);
            return () => {
                window.removeEventListener("resize", setPadding);
            };
        }, []);

        return (
            <Card style={{ height: "100%" }} onClick={() => {setTimeout(() => window.scrollTo(0, 0), 0); setSelectedMediaFolder(props.data)}} className={`identifiable-element ${thisHighlighted ? 'highlighted' : ''} hover:bg-[#fafafa] cursor-pointer`}>
                <Card.Header>
                    <Card.Title style={{ fontSize: "28px" }}>{props.data.title}</Card.Title>
                </Card.Header>

                <div className="p-4" style={{ height: "100%" }}>
                    <Flex className="flex-col h-100">
                        {props.data.files && (
                            <Flex style={{ minWidth: "200px", minHeight: "370px" }}>
                                <div className={"stacked-image"} style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                    {order === 1 && (
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation(); order < 3 ? setOrder(order + 1) : setOrder(1);
                                            }}
                                        >
                                            <Image1 />
                                            <Image2 />
                                            <Image3 />
                                        </div>
                                    )}
                                    {order === 2 && (
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation(); order < 3 ? setOrder(order + 1) : setOrder(1);
                                            }}
                                        >
                                            <Image2 />
                                            <Image3 />
                                            <Image1 />
                                        </div>
                                    )}
                                    {order === 3 && (
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation(); order < 3 ? setOrder(order + 1) : setOrder(1);
                                            }}
                                        >
                                            <Image3 />
                                            <Image1 />
                                            <Image2 />
                                        </div>
                                    )}
                                </div>
                            </Flex>
                        )}
                        <Flex ref={textRef} flexDirection="column" style={{minWidth: "300px", marginTop: props.data.files ? "10px" : "0",}}>
                            <Card.Text style={{ textAlign: "justify", whiteSpace: "pre-wrap" }}>{props.data.content}</Card.Text>
                        </Flex>
                    </Flex>
                </div>

                {props.data.url?.length > 0 && (
                    <Card.Body className="pt-0 w-100">
                        <Flex flexDirection="column" style={{ gap: "8px", maxHeight: "150px", overflowY: "auto" }}>
                            <p style={{ marginBottom: "3px", fontSize: "14px", fontWeight: "bold"}} className={"text-lg"}>Liens associés:</p>
                            {props.data.url.map((link, index) => (
                                <Flex
                                    key={index}
                                    alignItems="center"
                                    style={{
                                        flexWrap: "wrap",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Card.Link
                                        onClick={() =>
                                            link.link.startsWith("https://") ? window.open(link.link, "_blank", "noopener,noreferrer") : navigate(link.link)
                                        }
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                            textUnderlineOffset: "3px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "95%",
                                        }}
                                        title={link.link}
                                    >
                                        {link.title}
                                    </Card.Link>
                                </Flex>
                            ))}
                        </Flex>
                    </Card.Body>
                )}

                <Card.Footer className="mt-auto py-3">
                    <Flex flexDirection="column" justifyContent="space-between" flexWrap="wrap">
                        <Flex className={"px-4 mb-2"} width={"100%"} style={{gap: "10px"}}>
                            {authContext.currentUser?.isAdmin &&
                                <Button icon={<CopyOutlined/>} style={{width: "100%"}} className={"mb-2"} onMouseDown={(e) => {e.preventDefault();}} onBlur={(e) => e.preventDefault()} onClick={(e) => {e.stopPropagation(); navigator.clipboard.writeText("/?id="+props.data._id).then(() => {message.success("Le lien à été copié" + " ("+props.data.title+")")}).catch((err) => {console.error(err)})}}>
                                    Copier le lien
                                </Button>
                            }
                            {authContext.currentUser?.isAdmin && (
                                <Button icon={<EditOutlined />} style={{ width: "max-content" }} className="mb-2 mx-auto w-100" onClick={(e) => {e.stopPropagation(); setNewElement({ ...props.data, url: props.data.url || [] });setNumberOfLinks(props.data.url ? props.data.url.length : 0);setElementId(props.data._id);setFileList(props.data.files);onOpen();}}>
                                    Modifier
                                </Button>
                            )}
                        </Flex>

                        <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "5px" }}>
                            <MyText className="text-muted mx-auto" style={{ fontSize: "var(--MiniFontSize)" }} value={"Entreprises concernées: "}/>
                            {props.data.concern.map((item, idx) => (
                                <MyText key={item} className="text-muted mx-auto lowercase" style={{ fontSize: "var(--MiniFontSize)" }} value={item === "All" ? "" : item + (idx !== props.data.concern.length - 1 ? ", " : "")}/>
                            ))}
                        </div>

                        {props.data.updatedAt && (
                            <small className="text-muted mt-auto mx-auto pe-3">
                                {"Dernière mise à jour il y a " + formatTimeDifference(props.data.updatedAt)}
                            </small>
                        )}
                    </Flex>
                </Card.Footer>
            </Card>

        )
    }

    const renderGallery = () => {
        return (
            <div className={"w-100"}>
                {selectedMediaFolder._id !== "" && <p className={"mb-2 font-bold text-lg"}>{selectedMediaFolder.title}</p>}
                {selectedMediaFolder._id !== "" && <p className={selectedMediaFolder.url.length === 0 ? "mb-4" : "mb-3"}>{selectedMediaFolder.content}</p>}
                {selectedMediaFolder._id !== "" &&
                    <Flex flexDirection="column" style={{ gap: "8px", maxHeight: "150px", overflowY: "auto", marginBottom: "10px" }}>
                        <p style={{ marginBottom: "3px", fontSize: "14px", fontWeight: "bold"}} className={"text-lg"}>Liens associés:</p>
                        {selectedMediaFolder.url.map((link, index) => (
                            <Flex
                                key={index}
                                alignItems="center"
                                style={{
                                    flexWrap: "wrap",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Card.Link
                                    onClick={() =>
                                        link.link.startsWith("https://") ? window.open(link.link, "_blank", "noopener,noreferrer") : navigate(link.link)
                                    }
                                    style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        textUnderlineOffset: "3px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "95%",
                                    }}
                                    title={link.link}
                                >
                                    {link.title}
                                </Card.Link>
                            </Flex>
                        ))}
                    </Flex>
                }
                <Row xxl={5} style={{width: "100%"}} className="g-4 mt-0 mb-1 mx-auto">
                    {selectedMediaFolder.files.map((thisElement, elementIndex) => (
                        <Col style={{maxWidth: windowDimensions.wWCheck(900) ? "20%" : ""}} key={elementIndex}>
                            <MyGallery data={selectedMediaFolder} element={thisElement} index={elementIndex} key={elementIndex}/>
                        </Col>
                    ))}
                </Row>
            </div>
        )
    };

    const renderFiltered = () => {
        const userConcernSet = new Set();
        let numberElementsDisplayed = 0;

        userConcernSet.add("All");
        authContext.currentUser?.concern.forEach((element) => {
            userConcernSet.add(element);
        });

        const userConcerns = Array.from(userConcernSet);

        const elementFilter = (element, userConcern, filter) => {
            const matchesUserConcern = authContext.currentUser?.isAdmin ||
                element.concern.includes("All") ||
                element.concern.some(r => userConcern.includes(r));
            const matchesFilter = filter === "" || element.concern.includes(filter);

            return matchesUserConcern && matchesFilter;
        };

        const renderDividerSection = (filter) => {
            const filtered = mediasFolders.filter(thisElement => elementFilter(thisElement, userConcerns, filter));

            if (filtered.length > 0) {
                numberElementsDisplayed += filtered.length;

                return (
                    <div style={{ width: "100%" }} key={filter}>
                        <Divider className={"my-6"} style={{borderColor: "var(--Blue", color: "var(--Blue", fontWeight: "bold" }}>{(filter === "All" ? "" : filter) + " (" + filtered.length + ")"}</Divider>
                        <Row xxl={3} style={{ width: "100%" }} className="g-4 mt-0 mb-1">
                            {filtered.map((thisElement, elementIndex) => (
                                <Col style={{ maxWidth: windowDimensions.wWCheck(900) ? "50%" : "" }} key={elementIndex}>
                                    <MyCard data={thisElement} key={elementIndex} />
                                </Col>
                            ))}
                        </Row>
                    </div>
                );
            }
            return null;
        };

        if (filter === "") {
            return userConcerns.map((concern) => renderDividerSection(concern));
        } else {
            return renderDividerSection(filter) || (
                <div style={{ width: "100%" }}>
                    <Flex justifyContent={"center"} className={"text-muted"} style={{ width: "100%", marginTop: "100px" }} key="no-medias-folders">
                        <MyText value={"Rien à afficher pour le moment"} style={{ fontSize: "var(--MediumFontSize)" }} />
                    </Flex>
                </div>
            );
        }
    };

    return (
        <div style={{ marginBottom: "auto" }}>
            <PageTitle title={"Médias"}>
                {selectedMediaFolder._id !== "" &&
                    <div className={"mt-auto"}>
                        <Button onClick={() => setSelectedMediaFolder({_id: "", title: "", concern: [], content: "", url: [], files: ""})} type={"primary"} icon={<BackwardOutlined/>} style={{ height: "min-content", marginTop: "auto", marginBottom: "auto" }} className="me-2">
                            Retour aux dossiers de médias
                        </Button>
                    </div>
                }
                {authContext.currentUser?.isAdmin && selectedMediaFolder._id === "" &&
                    <Button onClick={onOpen} type={"primary"} icon={<PlusOutlined/>} style={{ height: "min-content", marginTop: "auto", marginBottom: "auto" }} className="me-2">
                        Ajouter un dossier de médias
                    </Button>
                }
                {authContext.currentUser?.concern && authContext.currentUser?.concern.length > 1 &&
                    <Select onChange={(values) => values === undefined ? setFilter("") : setFilter(values)} className={"w-56 mr-5 ml-auto"} placeholder="Filtrer l'entreprise" allowClear={true}>
                        {authContext.currentUser?.concern.sort().map((concern) => (
                            <Select.Option key={concern} value={concern}>{concern}</Select.Option>
                        ))}
                    </Select>
                }
            </PageTitle>

            {selectedMediaFolder?._id === "" &&
                <Flex justifyContent={"center"} alignItems={"center"} flexWrap={"wrap"}>
                    {loading && <Loading />}
                    <Row xxl={3} style={{ width: "100%" }} className="g-4 mb-5">
                        {!loading && (mediasFolders.length === 0 ?
                                <Flex justifyContent={"center"} className={"text-muted"} style={{ width: "100%", marginTop: "150px" }}>
                                    <MyText value={"Rien à afficher pour le moment"} style={{ fontSize: "var(--MediumFontSize)" }} />
                                </Flex> :
                                renderFiltered()
                        )}
                    </Row>
                </Flex>
            }

            {selectedMediaFolder?._id !== "" &&
                <Flex justifyContent={"center"} alignItems={"center"} flexWrap={"wrap"}>
                    {loading && <Loading />}
                    <Row xxl={3} style={{ width: "100%" }} className="g-4 mt-1 mb-5">
                        {!loading && (selectedMediaFolder.files.length === 0 ?
                                <Flex justifyContent={"center"} className={"text-muted"} style={{ width: "100%", marginTop: "150px" }}>
                                    <MyText value={"Rien à afficher pour le moment"} style={{ fontSize: "var(--MediumFontSize)" }} />
                                </Flex> :
                                renderGallery()
                        )}
                    </Row>
                </Flex>
            }

            {isOpen && (
                <div className="modal-backdrop" onClick={() => {onClose();resetForm();}}>
                    <Modal style={{ padding: "0px", width: "100%" }} hideCloseButton={true} isOpen={isOpen} className={"modal flex flex-col h-max"} onClick={(e) => e.stopPropagation()}>
                        <ModalContent className={"rounded-lg border shadow-sm bg-white max-w-96"}>
                            <ModalHeader className={"font-bold text-2xl mt-3 mb-2 mx-auto"}>
                                {elementId ? "MODIFIER UN DOSSIER DE MEDIAS" : "CRÉER UN DOSSIER DE MEDIAS"}
                            </ModalHeader>
                            <form onSubmit={handleElementSubmit}>
                                <ModalBody>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Entreprises concernée(s)</span>
                                        <Select value={newElement.concern} onChange={(values) => setNewElement({ ...newElement, concern: values })} mode="multiple" placeholder="Sélectionnez les entreprises concernées" className="w-full rounded-lg">
                                            {authContext.currentUser?.concern.map((concern) => (
                                                <Select.Option key={concern} value={concern}>
                                                    {concern}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Titre du dossier</span>
                                        <Input type="text" placeholder="Titre du dossier" value={newElement.title} onChange={(e) => setNewElement({ ...newElement, title: e.target.value })} className="w-full rounded-lg"/>
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Description du dossier</span>
                                        <TextArea autoSize type="text" placeholder="Description du dossier" value={newElement.content} onChange={(e) => setNewElement({ ...newElement, content: e.target.value })} className="w-full rounded-lg"/>
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Médias dans le dossier</span>
                                        <Dropzone onChange={handleFileChange} initialFile={fileList} multiple={true}/>
                                    </div>
                                    {Array.from(Array(numberOfLinks).keys()).map((link, index) => (
                                        <div className="w-full">
                                            <span className="block text-gray-500 font-medium mb-1">Lien {index+1}</span>
                                            <Flex justifyContent={"space-between"} key={index}>
                                                <Input value={newElement.url[index]?.title || ""} onChange={(e) => handleTitleChange(index, e.target.value)} placeholder={`Titre ${index + 1}`} style={{width: "100%", marginRight: "3px"}}/>
                                                <Input value={newElement.url[index]?.link || ""} onChange={(e) => handleLinkChange(index, e.target.value)} placeholder={`Url ${index + 1}`} style={{width: "100%", marginLeft: "3px"}}/>
                                                <Button icon={<CloseOutlined />} style={{height: "min-content", marginTop: "auto", marginBottom: "auto", marginLeft: "6px", paddingLeft: "4px", paddingRight: "4px", color: "red"}} onClick={() => handleRemoveLink(index)}/>
                                            </Flex>
                                        </div>
                                    ))}
                                    <Button icon={<PlusOutlined />} type={"text"} disabled={numberOfLinks >= 5} style={{ width: "100%" }} className="me-2" onClick={() => {if (numberOfLinks < 5) setNumberOfLinks(numberOfLinks + 1);}}>
                                        Ajouter un lien
                                    </Button>
                                </ModalBody>
                                <ModalFooter className={"flex flex-col justify-between mt-3"}>
                                    <div className={"flex justify-between gap-2"}>
                                        <Button icon={<CloseOutlined/>} className={"rounded-lg w-44"} onClick={() => {onClose();resetForm();}}>
                                            Annuler
                                        </Button>
                                        {elementId && (
                                            <Popconfirm
                                                title="Supprimer le média"
                                                description="Êtes vous sur de vouloir le supprimer ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={() => {onClose(); resetForm();handleElementDelete()}}
                                            >
                                                <Button icon={<DeleteOutlined/>} type="primary" danger={true} className={"rounded-lg w-44 mx-auto"}>
                                                    Supprimer
                                                </Button>
                                            </Popconfirm>
                                        )}
                                        {elementId ? (
                                                <Popconfirm
                                                    title="Modifier le média"
                                                    description="Êtes-vous sûr de vouloir le modifier ?"
                                                    okText="Oui"
                                                    cancelText="Non"
                                                    onConfirm={handleElementSubmit}
                                                >
                                                    <Button
                                                        icon={<EditOutlined />}
                                                        type="primary"
                                                        className="rounded-lg w-44"
                                                    >
                                                        Modifier
                                                    </Button>
                                                </Popconfirm>
                                            ) :
                                            <Popconfirm
                                                title="Créer le média"
                                                description="Êtes-vous sûr de vouloir le créer ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={handleElementSubmit}
                                            >
                                                <Button
                                                    icon={<PlusOutlined />}
                                                    type="primary"
                                                    className="rounded-lg w-44"
                                                >
                                                    Créer
                                                </Button>
                                            </Popconfirm>
                                        }
                                    </div>
                                </ModalFooter>
                            </form>
                        </ModalContent>
                    </Modal>
                </div>
            )}

            {loading && <p className="mt-6 text-center">Loading...</p>}

            {isPictureViewerOpen && (
                <div style={{ position: "relative", zIndex: "9999" }}>
                    <ImageViewer src={[pictureViewerPicture]} onClose={() => setIsPictureViewerOpen(false)} disableScroll={false} backgroundStyle={{backgroundColor: "rgba(0,0,0,0.9)"}} closeOnClickOutside={true}/>
                </div>
            )}
        </div>
    );
};