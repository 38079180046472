import React from "react";
import { Text } from 'theme-ui'
import "../../css/constants.css"

interface Props {
    value: string | undefined
    style?: object;
    id?: string
    as?: string
    className?: string
    onClick?: () => void
}

export const MyText = (props: Props) => {
    return <Text as={props.as ? props.as as React.ElementType : "span"}
            sx={{
                color: "myBlack",
                fontSize: "var(--MidFontSize)",
                fontFamily: "myRegular",
                whiteSpace: "pre-wrap"
            }}
                 className={props.className}
            style={props.style} id={props.id} onClick={props.onClick}>
        {props.value}
    </Text>
};

export default MyText;
