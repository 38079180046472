export interface IText {
    news: string
    calendar: string
    documents: string
    media: string
    contact: string
    history: string
    facilis: string
    manage: string
}

const translations: Map<string, IText> = new Map<string, IText>();

translations.set('fr', {
    news: "Actualités",
    calendar: "Calendrier",
    documents: "Documents utiles",
    media: "Médias",
    contact: "Contacts",
    history: "Histoire",
    facilis: "Facilis",
    manage: "Gestion"
})
export default translations;
