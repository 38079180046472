import React from "react";
import { Button } from 'react-bootstrap'
import "../../css/MyButton.css"

interface Props {
    value: string
    onClick?: () => void;
    onDoubleClick?: () => void;
    style?: object;
    styleHovered?: object;
    variant?: string;
    className?: string;
    ref?: (node?: (Element | null | undefined)) => void;
    disabled?: boolean
    buttonColor?: string
    id?: string;
}

export const MyButton = (props: Props) => {
    const [hovered, setHovered] = React.useState(false);

    return <Button
        id={props.id}
        variant={props.variant ?? 'primary'}
        ref={props.ref}
        className={`${props.className}`}
        disabled={props.disabled}
        style={hovered ? props.styleHovered ? props.styleHovered : props.style : props.style}
        onDoubleClick={props.onDoubleClick}
        onMouseEnter={() => {setHovered(true)}} onMouseLeave={() => {setHovered(false)}} onClick={props.onClick}>
        {props.value}
    </Button>
};

export default MyButton;
