import React, {useRef, useState} from 'react';
import { useNavigate} from 'react-router-dom';
import {Input, Button, message, Watermark, Row, Col} from "antd";
import axios from 'axios';
import Logo from "../../assets/pictures/truck.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';
import {useWindowDimensions} from "../../contexts/WindowDimensionsContext";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const PasswordReset = () => {
    const query = useQuery();
    const token = query.get("token");
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [status, setStatus] = useState({ newPassword: '', confirmPassword: '' });
    const windowDimensions = useWindowDimensions()
    const formRef = useRef(null);

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        if (name === 'newPassword') setNewPassword(value);
        else if (name === 'confirmPassword') setConfirmPassword(value);

        setStatus((prev) => ({
            ...prev,
            [name]: value.length >= 4 ? 'success' : 'error'
        }));
    };

    const validatePasswords = () => {
        if (newPassword !== confirmPassword) {
            message.error("Les mots de passe ne correspondent pas.");
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (!validatePasswords()) return;

        try {
            const response = await axios.post(`http://localhost:3001/api/user/reset-password/${token}`, {
                newPassword
            }, {
                headers: { "Content-Type": "application/json" }
            });

            if (response.data.success) {
                message.success("Mot de passe réinitialisé avec succès !");
                navigate("/login");
            } else {
                message.error(response.data.error || "Le lien de réinitialisation est invalide ou expiré.");
            }
        } catch (error) {
            console.error(error);
            message.error("Une erreur est survenue. Veuillez réessayer.");
        }
    };

    return (
        <Watermark zIndex={-1} content="Team Kimmel">
            <div style={{ height: windowDimensions.windowHeight }}>
                <div>
                    <Row className="justify-content-center align-items-center mx-2 pt-10">
                        <Col
                            style={{
                                background: "var(--White)",
                                padding: "30px",
                                borderRadius: "15px",
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                                width: "max-content",
                                minWidth: "510px"
                            }}
                            xs={12} sm={7} md={6} lg={4} xl={3}
                        >
                            <div className="text-center mb-6">
                                <h1 className="text-1500 text-3xl">Intranet Team Kimmel</h1>
                            </div>
                            <div className="d-flex align-items-center justify-content-center mb-6">
                                <img src={Logo} alt="Team Kimmel" width={450} className="reset-password-logo" style={{filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))", borderRadius: "10px"}}/>
                            </div>
                            <h3 className="text-1500 text-2xl text-custom-blue text-center">Réinitialiser le mot de passe</h3>
                            <p className="text-700 mt-0.5 text-center">Veuillez entrer votre nouveau mot de passe</p>

                            <form ref={formRef}>
                                <div className="form-group mt-6">
                                    <label htmlFor="newPassword">Nouveau mot de passe</label>
                                    <Input.Password
                                        id="newPassword"
                                        name="newPassword"
                                        placeholder="Nouveau mot de passe"
                                        value={newPassword}
                                        onChange={handlePasswordChange}
                                        status={status.newPassword}
                                        prefix={<FontAwesomeIcon icon={faKey} />}
                                        className="reset-input"
                                    />
                                </div>

                                <div className="form-group mt-3">
                                    <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
                                    <Input.Password
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        placeholder="Confirmer le mot de passe"
                                        value={confirmPassword}
                                        onChange={handlePasswordChange}
                                        status={status.confirmPassword}
                                        prefix={<FontAwesomeIcon icon={faKey} />}
                                        className="reset-input"
                                    />
                                </div>

                                <Button
                                    type="primary"
                                    className="w-100 mt-6"
                                    onClick={handleSubmit}
                                    disabled={!newPassword || !confirmPassword || status.newPassword === 'error' || status.confirmPassword === 'error'}
                                >
                                    Réinitialiser le mot de passe
                                </Button>
                            </form>
                        </Col>
                    </Row>
                </div>
            </div>
        </Watermark>
    );
};

export default PasswordReset;
