import {createContext, useContext, useEffect, useState} from "react";
import {useTranslation} from "../contexts/TranslationContext"
import text, {IText} from "../translations/components/Context";
import axios from "axios";

const ConcernContext = createContext(undefined as any);

type ProviderConcernT = {
    concern: string[],
    setConcern: (concern: string[]) => void,
    concernOptions: string[],
    setConcernOptions: (concernOptions: string[]) => void,
    concernAll: boolean,
    setConcernAll: (concernAll: boolean) => void
}

export const useConcern = () : ProviderConcernT => {
    return useContext(ConcernContext);
}

interface IProps {
    children: JSX.Element[] | JSX.Element;
}

const ConcernProvider = ({children}: IProps) => {
    const language = useTranslation().currentLanguage;
    const texts = (text.get(language) as IText)

    const [concern, setConcern] = useState([]);
    const [concernOptions, setConcernOptions] = useState([]);
    const [concernAll, setConcernAll] = useState(true);

    useEffect(() => {
        axios.get(`http://localhost:3001/api/concern`, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            if (res.data.success !== false) {
                setConcernOptions(res.data.response)
            }
        }).catch((err) => {console.error(err)})
    }, []);

    const value = {
        concern,
        setConcern,
        concernOptions,
        setConcernOptions,
        concernAll,
        setConcernAll
    }

    return (
        <ConcernContext.Provider value={value}>
            {children}
        </ConcernContext.Provider>
    );
}

export default ConcernProvider;
