import React from 'react';
import { Button, Layout, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

const { Footer: AntFooter } = Layout;
const { Text, Link } = Typography;

const Footer = () => {
    const navigate = useNavigate();

    const handleScrollToTop = (path) => {
        scroll.scrollToTop({ duration: 0 });
        navigate(path);
    };

    return (
        <AntFooter
            style={{
                background: '#f0f2f5',
                padding: '10px 40px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '60px',
                boxShadow: '0px -2px 3px -2px rgba(0,0,0,0.2)',
            }}
        >
            <Button
                // type="primary"
                size="small"
                onClick={() => handleScrollToTop('/contact-us')}
            >
                Nous contacter
            </Button>

            {/*<div style={{ display: 'flex', gap: '10px' }}>*/}
            {/*    /!*<Link*!/*/}
            {/*    /!*    onClick={() => handleScrollToTop('/legal')}*!/*/}
            {/*    /!*    style={{ fontSize: '0.85rem', color: 'var(--DarkGray)' }}*!/*/}
            {/*    /!*>*!/*/}
            {/*    /!*    Legal Notice*!/*/}
            {/*    /!*</Link>*!/*/}
            {/*    /!*<Text type="secondary" style={{ fontSize: '0.85rem' }}>|</Text>*!/*/}
            {/*    /!*<Link*!/*/}
            {/*    /!*    onClick={() => handleScrollToTop('/privacy')}*!/*/}
            {/*    /!*    style={{ fontSize: '0.85rem', color: 'var(--DarkGray)' }}*!/*/}
            {/*    /!*>*!/*/}
            {/*    /!*    Privacy Policy*!/*/}
            {/*    /!*</Link>*!/*/}
            {/*</div>*/}

            <Text type="secondary" style={{ color: 'var(--DarkGray)' }}>
                © Kimmel Intranet 2024
            </Text>
        </AntFooter>
    );
};

export default Footer;
