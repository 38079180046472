import React, { useState, useEffect, useRef } from "react";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024;
const MAX_FILE_COUNT = 50;

const Dropzone = ({ onChange, initialFile = [], fileType = null, multiple = false, setFileRemoved = () => {}, required = false }) => {
    const [fileList, setFileList] = useState([]);
    const isInitialFileSet = useRef(false);

    const acceptedFileTypes = fileType === "image"
        ? "image/*"
        : fileType === "document"
            ? ".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .odt, .ods, image/*"
            : "*";

    useEffect(() => {
        if (!isInitialFileSet.current && initialFile) {
            const initialFilesArray = Array.isArray(initialFile) ? initialFile : [initialFile];

            const uniqueInitialFiles = initialFilesArray.map((file, index) => {
                if (typeof file === 'string') {
                    const name = file.match(/\/uploads\/(?:\d+-)?(.+)/)?.[1] || `File-${index + 1}`;
                    return {
                        uid: `${Date.now()}-${index}`,
                        name: name,
                        url: file,
                        status: 'done'
                    };
                }
                return {
                    ...file,
                    uid: file.uid || `${Date.now()}-${index}`,
                    name: file.name || `File-${index + 1}`
                };
            });

            setFileList(uniqueInitialFiles);
            isInitialFileSet.current = true;
        }
    }, [initialFile]);

    useEffect(() => {
        return () => {
            fileList.forEach(file => {
                if (file.url && file.originFileObj) {
                    URL.revokeObjectURL(file.url);
                }
            });
        };
    }, [fileList]);

    const handleBeforeUpload = (file) => {
        if (file.size > MAX_FILE_SIZE) {
            message.error(`Le fichier "${file.name}" dépasse la limite de 2GB et ne peut pas être téléversé.`);
            return Upload.LIST_IGNORE;
        }
        return false;
    };

    const handleChange = (info) => {
        if (info.fileList.length > 0) {
            setFileRemoved(false);
        }

        let newFiles = info.fileList
            .filter(file => file.size <= MAX_FILE_SIZE)
            .map((file, index) => ({
                ...file,
                uid: file.uid || `${Date.now()}-${index}`,
                name: file.name || `File-${index + 1}`,
                url: file.url || (file.originFileObj ? URL.createObjectURL(file.originFileObj) : undefined)
            }));

        newFiles = newFiles.filter(
            (newFile) => !(fileList || []).some(
                (existingFile) => existingFile.name === newFile.name && existingFile.size === newFile.size
            )
        );

        const updatedFileList = multiple ? [...fileList, ...newFiles].slice(0, MAX_FILE_COUNT) : newFiles.slice(-1);
        setFileList(updatedFileList);

        if (newFiles.length > 0) {
            onChange(multiple ? updatedFileList.map(file => file.originFileObj || file) : updatedFileList[0]?.originFileObj || null);
        }
    };

    const handleRemove = (file) => {
        const updatedList = (fileList || []).filter(item => item.uid !== file.uid);
        setFileList(updatedList);

        if (updatedList.length === 0) {
            onChange(null);
            setFileRemoved(true);
        } else {
            onChange(multiple ? updatedList.map(file => file.originFileObj || file) : updatedList[0]?.originFileObj || null);
        }
    };

    return (
        <Dragger
            multiple={multiple}
            fileList={fileList}
            beforeUpload={handleBeforeUpload}
            accept={acceptedFileTypes}
            onChange={handleChange}
            onRemove={handleRemove}
            listType={fileType === "image" ? "picture" : "text"}
            showUploadList={{
                showPreviewIcon: fileType === "image",
                showRemoveIcon: !multiple,
                showDownloadIcon: false,
            }}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Cliquez ou glissez le fichier dans cette zone pour le télécharger</p>
            <p className="ant-upload-hint">
                {multiple ? `Téléchargement unique ou multiple | Max 2GB par fichier | Max ${MAX_FILE_COUNT} fichiers` : "Supporte un téléchargement unique | Limite de 2GB du fichier"}
            </p>
        </Dragger>
    );
};

export { Dropzone };
