import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

interface IProps {
  icon: IconDefinition;
  children: React.ReactNode;
  link: string;
  className?: string;
  onClick?: () => void;
}

export const NavItem = (props: IProps) => {
  const location = useLocation();

    return (
        <Nav.Item>
            <Link to={props.link} onClick={props.onClick} className={`nav-link px-3 ${props.className} ${location.pathname == props.link ? 'active' : ''}`}>
                <FontAwesomeIcon icon={props.icon} className="me-2" />
                <span>{props.children}</span>
            </Link>
        </Nav.Item>
    );
};
