import React from "react";
import {Flex} from "rebass";
import {PropagateLoader} from "react-spinners";

interface Props {
    marginTop?: string;
    marginBottom?: string;
    scale?: string;
}

export function Loading(props: Props) {
    return (
        <Flex width={"100%"} marginTop={props.marginTop ? props.marginTop : "150px"} marginBottom={props.marginBottom ? props.marginBottom : ""} justifyContent={"center"}>
            <PropagateLoader
                cssOverride={{scale: props.scale ? props.scale : ""}}
            />
        </Flex>
    )
}