import {Flex} from 'rebass';
import MyButton from "../../components/basics/MyButton";
import MyText from "../../components/basics/MyText";
import {useCounter} from "../../contexts/CounterContext";
import counterRoutes from "../../api/controllers/counter";
import {ICounter} from "../../api/models/counter";
import {useEffect} from "react";

const Counter = () => {
    const counterContext = useCounter()

    const resetCounter = (name: string) => {
        counterRoutes.updateByName(name, {name: name, number: 0} as ICounter).then(() => {
            counterRoutes.getByName(name).then((res) => {
                if (name === "totalnumber")
                    counterContext.setTotalCounter(res.data.response[0].number)
                if (name === "daynumber")
                    counterContext.setDayCounter(res.data.response[0].number)
                if (name === "resetnumber")
                    counterContext.setResetCounter(res.data.response[0].number)
            }).catch((err) => {console.error(err)})
        }).catch((err) => {console.error(err)})
    }

    const getCounter = (name: string) => {
        counterRoutes.getByName(name).then((res) => {
            if (name === "totalnumber")
                counterContext.setTotalCounter((window.location.pathname.toLocaleLowerCase() !== "/counter") ? res.data.response[0].number+1 : res.data.response[0].number)
            if (name === "daynumber")
                counterContext.setDayCounter((window.location.pathname.toLocaleLowerCase() !== "/counter") ? res.data.response[0].number+1 : res.data.response[0].number)
            if (name === "resetnumber")
                counterContext.setResetCounter((window.location.pathname.toLocaleLowerCase() !== "/counter") ? res.data.response[0].number+1 : res.data.response[0].number)
        }).catch((err) => {console.error(err)})
    }

    useEffect(() => {
        setInterval(() => {
            getCounter("totalnumber")
            getCounter("daynumber")
            getCounter("resetnumber")
        }, 60000)
    }, [])

    return (
        <Flex flexDirection={"column"}>
            <MyButton value={"Update"} onClick={() => {getCounter("totalnumber"); getCounter("daynumber"); getCounter("resetnumber")}} style={{marginTop: "25px"}}/>
            <MyButton value={"Reset Total Counter"} onClick={() => resetCounter("totalnumber")} style={{marginTop: "25px"}}/>
            <MyButton value={"Reset Day Counter"} onClick={() => resetCounter("daynumber")} style={{marginTop: "25px"}}/>
            <MyButton value={"Reset Reset Counter"} onClick={() => resetCounter("resetnumber")} style={{marginTop: "25px"}}/>
            <Flex>
                <MyText value={"Nombre de visiteurs Total : "}/>
                <MyText value={counterContext.totalCounter.toString()}/>
            </Flex>
            <Flex>
                <MyText value={"Nombre de visiteurs Aujourd'hui : "}/>
                <MyText value={counterContext.dayCounter.toString()}/>
            </Flex>
            <Flex>
                <MyText value={"Nombre depuis remise à zéro : "}/>
                <MyText value={counterContext.resetCounter.toString()}/>
            </Flex>
        </Flex>
    )
}

export default Counter;
