import {createContext, useContext, useState} from "react";

const CounterContext = createContext(undefined as any);

type ProviderCounterT = {
    totalCounter: number,
    setTotalCounter: (totalCounter: number) => void,
    resetCounter: number,
    setResetCounter: (resetCounter: number) => void
    dayCounter: number,
    setDayCounter: (dayCounter: number) => void
}

export const useCounter = () :ProviderCounterT => {
    return useContext(CounterContext);
}

interface IProps {
    children: JSX.Element[] | JSX.Element;
}

const CounterProvider = ({children}: IProps) => {
    const [totalCounter, setTotalCounter] = useState(0);
    const [resetCounter, setResetCounter] = useState(0);
    const [dayCounter, setDayCounter] = useState(0);

    const value = {
        totalCounter,
        setTotalCounter,
        resetCounter,
        setResetCounter,
        dayCounter,
        setDayCounter
    }

    return (
        <CounterContext.Provider value={value}>
            {children}
        </CounterContext.Provider>
    );
}

export default CounterProvider;
