import { useState } from 'react';
import { Card, Dropdown, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Avatar from './Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUser } from '@fortawesome/free-regular-svg-icons';
import { useAuth } from '../contexts/AuthContext';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from '../contexts/TranslationContext';
import texts, { IText } from '../translations/ProfileDropdownMenu';

interface ProfileDropdownMenuProps {
  className?: string;
  user: any;
}

const ProfileDropdownMenu = (props: ProfileDropdownMenuProps) => {
  const language = useTranslation().currentLanguage;
  const auth = useAuth();

  const [navItems] = useState([
    {
      label: (texts.get(language) as IText).itemMyProfile,
      icon: faUser,
      to: '/profile'
    },
    // {
    //   label: (texts.get(language) as IText).itemMyCompany,
    //   icon: faBuilding,
    //   to: '/company-details'
    // }
  ]);

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        props.className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 shadow border border-300'
      )}
    >
      <Card style={{width: "max-content"}} className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-row align-items-center gap-2 pt-3 px-3">
            <Avatar size="l" className='text-16' variant="name">{auth.currentUser?.firstName[0] as string + auth.currentUser?.lastName[0]}</Avatar>
            <div>
              <h6 className="text-black mb-0">{auth.currentUser?.firstName + ' ' + auth.currentUser?.lastName}</h6>
              <span className="text-muted">{auth.currentUser?.email}</span>
            </div>
          </div>
          <hr className='mt-3 mb-2' />
          <Nav className="nav flex-column px-0">
            {navItems.map(item => (
              <Nav.Item key={item.label}>
                <Link to={item.to} className="nav-link px-3">
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="me-2 text-900"
                  />
                  <span className="text-1000">{item.label}</span>
                </Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Body>
        <hr className='mt-2 mb-3' />
          <div className="px-3 mb-3">
            <Nav.Link
              onClick={auth.logout}
              className="btn btn-phoenix-danger d-flex flex-center w-100"
            >
              <FontAwesomeIcon icon={faRightFromBracket} className='me-2' />
              Se déconnecter
            </Nav.Link>
          </div>
          {/*<div className="my-3 mx-2 text-center fw-bold fs-10 text-600">*/}
          {/*  <Link className="text-600 me-1" to="/confidentiality">*/}
          {/*    {(texts.get(language) as IText).privacyPolicyTitle}*/}
          {/*  </Link>*/}
          {/*  •*/}
          {/*  <Link className="text-600 mx-1" to="/legal">*/}
          {/*    {(texts.get(language) as IText).legalTitle}*/}
          {/*  </Link>*/}
          {/*</div>*/}
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
