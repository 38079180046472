import React, {useEffect, useState} from 'react';
import {Button, Input, Select, Table, Tag, Popconfirm, Typography, message, Switch} from 'antd';
import {useAuth} from "../../contexts/AuthContext";
import {useWindowDimensions} from "../../contexts/WindowDimensionsContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure} from "@nextui-org/react";
import {CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import axios from "axios";
const { Search } = Input;

export default function DocumentsFoldersTable() {
    const authContext = useAuth();
    const windowDimensions = useWindowDimensions();
    const [documentFolders, setDocumentFolders] = useState([]);
    const [displayedDocumentFolders, setDisplayedDocumentFolders] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [elementId, setElementId] = useState(null);
    const [newElement, setNewElement] = useState({_id: "", name: ""});

    const columns = [
        {
            title: "Nom du dossier",
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className={"flex gap-2"}>
                    <Popconfirm
                        title="Supprimer le dossier"
                        description="Êtes vous sur de vouloir le supprimer ?"
                        okText="Oui"
                        cancelText="Non"
                        onConfirm={() => handleElementDelete(record._id ? record._id : "")}
                    >
                        <Button icon={<FontAwesomeIcon icon={faTrash} />} danger />
                    </Popconfirm>
                    <Button icon={<FontAwesomeIcon icon={faPen} />} onClick={() => { onOpen(); setNewElement(record); setElementId(record._id) }} />
                    <Button icon={<FontAwesomeIcon icon={faEye} />} onClick={() => { onOpen(); setNewElement(record); setElementId("view")}} />
                </div>
            ),
            width: 200,
        },
    ];

    useEffect(() => {
        fetchElements();
    }, []);

    const fetchElements = async () => {
        try {
            const response = await axios.get(`http://localhost:3001/api/documents-folders/`);
            if (response.status === 200 && response.data.response.length > 0) {
                setDocumentFolders(response.data.response.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                }));
                setDisplayedDocumentFolders(response.data.response.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                }));
            } else if (response.status === 404) {
                setDocumentFolders([]);
                setDisplayedDocumentFolders([])
            }
        } catch (error) {
            setDocumentFolders([]);
            setDisplayedDocumentFolders([]);
            console.error('Error fetching documentFolders:', error);
        }
    };

    const handleElementSubmit = async (event) => {
        event?.preventDefault();

        try {
            if (elementId) {
                await axios.put(`http://localhost:3001/api/documents-folders/id/${elementId}`, newElement, {
                    headers: { "Content-Type": "application/json" },
                });
            } else {
                await axios.post(`http://localhost:3001/api/documents-folders/`, newElement, {
                    headers: { "Content-Type": "application/json" },
                });
            }
            onClose()
            resetForm();
            fetchElements()
        } catch (error) {
            console.error("Error submitting documentFolder:", error);
        }
    };

    const handleElementDelete = async (id) => {
        try {
            await axios.delete(`http://localhost:3001/api/documents-folders/id/${id ? id : elementId}`);
            resetForm();
            fetchElements()
        } catch (error) {
            console.error("Error deleting documentFolder:", error);
        }
    }

    const handleMultipleElementDelete = async (selectedKeys) => {
        try {
            selectedKeys.selectedRowKeys.map((id) =>
                handleElementDelete(id)
            )
            setSelectedRowKeys([]);
            fetchElements();
        } catch (error) {
            console.error("Error deleting documentFolders:", error);
            message.error("An error occurred while deleting documentFolders.");
        }
    };

    const resetForm = () => {
        setElementId(null);
        setNewElement({
            _id: "",
            firstName: "",
            lastName: "",
            email: "",
            concern: [],
            isEmailValid: false,
            isAdmin: false,
        });
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys),
    };

    const handleSearch = (value) => {
        const searchValue = value.toLowerCase();
        if (searchValue) {
            const filteredDocumentFolders = documentFolders.filter(document =>
                Object.values(document).some(val =>
                    String(val).toLowerCase().includes(searchValue)
                )
            );
            setDisplayedDocumentFolders(filteredDocumentFolders);
        } else {
            setDisplayedDocumentFolders(documentFolders);
        }
    };

    return (
        <div>
            <div style={{ marginBottom: "30px", width: "100%", marginTop: "40px" }}>
                <div className={"w-100 flex justify-between"}>
                    <div>
                        {authContext.currentUser?.isAdmin && (
                            <Button type={"primary"} icon={<FontAwesomeIcon icon={faPlus} />} onClick={() => onOpen()}>
                                Ajouter un dossier
                            </Button>
                        )}
                        {selectedRowKeys.length > 0 && (
                            <Popconfirm
                                title="Supprimer les dossiers"
                                description="Êtes vous sur de vouloir les supprimer ?"
                                okText="Oui"
                                cancelText="Non"
                                onConfirm={() => {handleMultipleElementDelete(rowSelection); setSelectedRowKeys([])}}
                            >
                                <Button
                                    icon={<FontAwesomeIcon icon={faTrash} />}
                                    danger
                                    style={{ marginLeft: 8 }}
                                >
                                    Supprimer les éléments sélectionnés
                                </Button>
                            </Popconfirm>
                        )}
                    </div>
                    <Search placeholder="Recherche"
                            onSearch={handleSearch} allowClear enterButton style={{ width: 250 }}/>
                </div>
            </div>
            <Table
                rowKey="_id"
                rowSelection={rowSelection}
                columns={columns}
                dataSource={displayedDocumentFolders}
                pagination={{ pageSize: 12 }}
                size="middle"
                scroll={{ x: windowDimensions.windowWidth < 900 ? 800 : undefined }}
            />

            {isOpen && (
                <div className="modal-backdrop" onClick={() => {onClose(); resetForm();}}>
                    <Modal style={{ padding: "0px", width: "100%" }} hideCloseButton={true} isOpen={isOpen} className={"modal flex flex-col h-max"} onClick={(e) => e.stopPropagation()}>
                        <ModalContent className={"rounded-lg border shadow-sm bg-white max-w-96"}>
                            <ModalHeader className={"font-bold text-2xl mt-3 mb-2 mx-auto"}>
                                {elementId ? elementId === "view" ? "DOSSIER DE DOCUMENT" : "MODIFIER UN DOSSIER DE DOCUMENT" : "CRÉER UN DOSSIER DE DOCUMENT"}
                            </ModalHeader>
                            <form onSubmit={handleElementSubmit}>
                                <ModalBody>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Nom du dossier <span className={"text-custom-blue"}>{elementId !== "view" ? "*" : ""}</span></span>
                                        {elementId !== "view" && <Input type="text" placeholder="NOM DU DOSSIER" value={newElement.name} onChange={(e) => setNewElement({ ...newElement, name: e.target.value.toUpperCase() })} className="w-full rounded-lg"/>}
                                        {elementId === "view" && <Typography.Text>{newElement.name}</Typography.Text>}
                                    </div>
                                </ModalBody>
                                <ModalFooter className={"flex flex-col justify-between mt-3"}>
                                    <div className={`flex ${elementId === "view" ? "justify-center" : "justify-between"} gap-2`}>
                                        <Button icon={<CloseOutlined/>} className={"rounded-lg w-44"} onClick={() => {onClose();resetForm();}}>
                                            {elementId === "view" ? "Fermer" : "Annuler"}
                                        </Button>
                                        {elementId && elementId !== "view" && (
                                            <Popconfirm
                                                title="Supprimer le dossier"
                                                description="Êtes vous sur de vouloir le supprimer ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={() => {onClose(); resetForm(); handleElementDelete();fetchElements()}}
                                            >
                                                <Button icon={<DeleteOutlined/>} type="primary" danger={true} className={"rounded-lg w-44 mx-auto"}>
                                                    Supprimer
                                                </Button>
                                            </Popconfirm>
                                        )}
                                        {elementId && elementId !== "view" ? (
                                            <Popconfirm
                                                title="Modifier le dossier"
                                                description="Êtes-vous sûr de vouloir le modifier ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={() => {onClose();resetForm();handleElementSubmit();fetchElements()}}
                                            >
                                                <Button
                                                    icon={<EditOutlined />}
                                                    type="primary"
                                                    className="rounded-lg w-44"
                                                >
                                                    Modifier
                                                </Button>
                                            </Popconfirm>
                                        ) : elementId !== "view" ? (
                                            <Popconfirm
                                                title="Créér le dossier"
                                                description="Êtes-vous sûr de vouloir le créér ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={() => { onClose(); resetForm(); handleElementSubmit();fetchElements()}}
                                            >
                                                <Button
                                                    icon={<PlusOutlined />}
                                                    type="primary"
                                                    className="rounded-lg w-44"
                                                >
                                                    Créer
                                                </Button>
                                            </Popconfirm>
                                        ) : null}
                                    </div>
                                </ModalFooter>
                            </form>
                        </ModalContent>
                    </Modal>
                </div>
            )}
        </div>
    );
}