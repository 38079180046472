import "../../css/constants.css";
import React, {useState} from "react";
import {Toaster} from 'sonner'
import PageTitle from "../../components/PageTitle";
import UsersTable from "./usersTable";
import { Radio } from "antd";
import ConcernsTable from "./concerns";
import DocumentsFoldersTable from "./documentsFolders";

export default function Manage() {
    const [selectedFolder, setSelectedFolder] = useState( "Utilisateurs");

    const folders = [
        "Utilisateurs",
        "Entreprises",
        "Dossiers de documents"
    ];

    return (
        <div style={{marginBottom: "auto"}}>
            {/*<Toaster richColors/>*/}
            <PageTitle title={"Gestion"}>
                <Radio.Group  optionType="button" buttonStyle="solid" value={selectedFolder} onChange={(e) => setSelectedFolder(e.target.value)}>
                    {folders && folders.map(thisElement =>
                        <Radio.Button value={thisElement}>{thisElement}</Radio.Button>
                    )}
                </Radio.Group>
            </PageTitle>
            {selectedFolder === "Utilisateurs" && <UsersTable/>}
            {selectedFolder === "Entreprises" && <ConcernsTable/>}
            {selectedFolder === "Dossiers de documents" && <DocumentsFoldersTable/>}
        </div>
    );
};