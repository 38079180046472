import {User} from "../contexts/AuthContext";

export const localStorageExist = () => typeof window !== "undefined" && "localStorage" in window && window["localStorage"] !== null

export const setLocalStorage = (name: string, value: string) => {
  if (localStorageExist()) {
    localStorage.setItem(name, value);
  }
};

export const removeLocalStorage = (name: string) => {
  if (localStorageExist()) {
    localStorage.removeItem(name);
  }
};

export const getFromLocalStorage = (name: string) => localStorageExist() ? localStorage.getItem(name) : null;

export const setUser = (user: User) => {
  setLocalStorage("user", JSON.stringify(user))
};

export const resetUser = () => {
  removeLocalStorage("user")
};

export const getUser = () => {
  const user = getFromLocalStorage("user")
  let userJson;
  if (user) {
    userJson = JSON.parse(user);
    return userJson;
  }
  return null;
};
