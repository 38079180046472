import React, {useEffect, useState} from 'react';
import {Button, Input, Select, Table, Tag, Popconfirm, Typography, message, Switch} from 'antd';
import {useAuth} from "../../contexts/AuthContext";
import {useWindowDimensions} from "../../contexts/WindowDimensionsContext";
import {useConcern} from "../../contexts/ConcernContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure} from "@nextui-org/react";
import {CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined, CheckOutlined} from "@ant-design/icons";
import axios from "axios";
const { Search } = Input;

export default function UsersTable() {
    const authContext = useAuth();
    const windowDimensions = useWindowDimensions();
    const [users, setUsers] = useState([]);
    const [displayedUsers, setDisplayedUsers] = useState([]);
    const [displayedNewUsers, setDisplayedNewUsers] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [elementId, setElementId] = useState(null);
    const [isNewUser, setIsNewUser] = useState(false);
    const [newElement, setNewElement] = useState({_id: "", firstName: "", lastName: "", email: "", password: "defaultPassword", concern: [], userValidated: true, isEmailValid: false, isAdmin: false, isSuperAdmin: false});
    const concernContext = useConcern();

    const ValidateButton = ({ record }) => {
        const [isHovered, setIsHovered] = useState(false);

        return (
            <Button
                icon={<CheckOutlined style={{ color: "green" }} />}
                onClick={() => {
                    onOpen();
                    record.userValidated = true;
                    setNewElement(record);
                    setElementId(record._id);
                    setIsNewUser(true)
                }}
                style={{
                    borderColor: isHovered ? "#77b97a": "green",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
        );
    };

    const columnsNewUsers = [
        {
            title: "Prénom",
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: "Nom",
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: "Email",
            dataIndex: 'email',
            key: 'email',
            width: '350px',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className={"flex gap-2"}>
                    <Popconfirm
                        title="Supprimer l'utilisateur"
                        description="Êtes vous sur de vouloir le supprimer ?"
                        okText="Oui"
                        cancelText="Non"
                        onConfirm={() => handleElementDelete(record._id ? record._id : "")}
                    >
                        <Button icon={<FontAwesomeIcon icon={faTrash} />} danger />
                    </Popconfirm>
                    <ValidateButton record={record}/>
                </div>
            ),
            width: 200,
        },
    ];

    const columns = [
        {
            title: "Prénom",
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: "Nom",
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: "Email",
            dataIndex: 'email',
            key: 'email',
            width: '350px',
        },
        {
            title: "Entreprises concernées",
            dataIndex: 'concern',
            key: 'concern',
            render: (concern) => (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', maxWidth: '740px', marginTop: "4px", marginBottom: "4px" }}>
                    {concern.map((item, index) => (
                        <Tag key={index} className="lowercase">{item}</Tag>
                    ))}
                </div>
            ),
            width: '800px',
        },
        {
            title: "Email validé",
            dataIndex: 'isEmailValid',
            key: 'isEmailValid',
            render: (isEmailValid) => (
                <Tag color={isEmailValid ? "green" : "red"}>{isEmailValid ? "Oui" : "Non"}</Tag>
            ),
        },
        {
            title: "Admin",
            dataIndex: 'isAdmin',
            key: 'isAdmin',
            render: (isAdmin) => (
                <Tag color={isAdmin ? "green" : "red"}>{isAdmin ? "Oui" : "Non"}</Tag>
            ),
        },
        {
            title: "Super Admin",
            dataIndex: 'isSuperAdmin',
            key: 'isSuperAdmin',
            render: (isSuperAdmin) => (
                <Tag color={isSuperAdmin ? "green" : "red"}>{isSuperAdmin ? "Oui" : "Non"}</Tag>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className={"flex gap-2"}>
                    <Popconfirm
                        title="Supprimer l'utilisateur"
                        description="Êtes vous sur de vouloir le supprimer ?"
                        okText="Oui"
                        cancelText="Non"
                        onConfirm={() => handleElementDelete(record._id ? record._id : "")}
                    >
                        <Button icon={<FontAwesomeIcon icon={faTrash} />} danger />
                    </Popconfirm>
                    <Button icon={<FontAwesomeIcon icon={faPen} />} onClick={() => { onOpen(); setNewElement(record); setElementId(record._id) }} />
                    <Button icon={<FontAwesomeIcon icon={faEye} />} onClick={() => { onOpen(); setNewElement(record); setElementId("view")}} />
                </div>
            ),
            width: 200,
        },
    ];

    useEffect(() => {
        fetchElements();
    }, []);

    const fetchElements = async () => {
        try {
            const response = await axios.get(`http://localhost:3001/api/user/`);
            if (response.status === 200 && response.data.data.length > 0) {
                let filteredUsers = response.data.data.filter(user =>
                    user.firstName !== 'Frédéric' || user.lastName !== 'Forster' || user.email !== 'forster.frederic@gmail.com'
                );

                const currentUser = authContext.currentUser;
                if (currentUser && currentUser.firstName === 'Frédéric' && currentUser.lastName === 'Forster' && currentUser.email === 'forster.frederic@gmail.com') {
                    filteredUsers.push(currentUser);
                }

                setUsers(filteredUsers.sort((a, b) => a.firstName.localeCompare(b.firstName)));
                setDisplayedNewUsers(filteredUsers
                    .filter(user => !user.userValidated)
                    .sort((a, b) => a.firstName.localeCompare(b.firstName)));

                setDisplayedUsers(filteredUsers
                    .filter(user => user.userValidated)
                    .sort((a, b) => a.firstName.localeCompare(b.firstName)));
            } else if (response.status === 404) {
                setUsers([]);
                setDisplayedUsers([]);
            }
        } catch (error) {
            setUsers([]);
            setDisplayedUsers([]);
            console.error('Error fetching users:', error);
        }
    };

    const handleSendVerifyEmail = async () => {
        try {
            const response = await axios.post(`http://localhost:3001/api/user/send-verify-email/${newElement.email}`);
            if (response.status === 200)
                message.success("Un email de réinitialisation de mot de passe à été envoyé à '" + newElement.email + "'.")
            else
                message.error("Une erreur s'est produite lors de l'envoi de l'email de réinitialisation de mot de passe.")
        } catch (error) {
            console.error("Error sending password reset email:", error);
        }
    }

    const handleElementSubmit = async (event) => {
        event?.preventDefault();

        if (newElement.concern.length === 0) {
            message.error("Attention l'utilisateur doit être assigné au minimum à une entreprise")
            return
        }

        try {
            if (elementId) {
                await axios.put(`http://localhost:3001/api/user/id/${elementId}`, newElement, {
                    headers: { "Content-Type": "application/json" },
                });
            } else {
                await axios.post(`http://localhost:3001/api/user/`, newElement, {
                    headers: { "Content-Type": "application/json" },
                });
            }
            onClose()
            resetForm();
            fetchElements()
        } catch (error) {
            console.error("Error submitting user:", error);
        }
    };

    const handleElementDelete = async (id) => {
        try {
            await axios.delete(`http://localhost:3001/api/user/id/${id ? id : elementId}`);
            onClose()
            resetForm();
            fetchElements()
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    }

    const handleMultipleElementDelete = async (selectedKeys) => {
        try {
            selectedKeys.selectedRowKeys.map((id) =>
                handleElementDelete(id)
            )
            setSelectedRowKeys([]);
            fetchElements();
        } catch (error) {
            console.error("Error deleting users:", error);
            message.error("An error occurred while deleting users.");
        }
    };

    const resetForm = () => {
        setElementId(null);
        setNewElement({
            _id: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "defaultPassword",
            concern: [],
            userValidated: true,
            isEmailValid: false,
            isAdmin: false,
            isSuperAdmin: false,
        });
        setIsNewUser(false)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys),
    };

    const handleSearch = (value) => {
        const searchValue = value.toLowerCase();
        if (searchValue) {
            const filteredUsers = users.filter(user =>
                Object.values(user).some(val =>
                    String(val).toLowerCase().includes(searchValue)
                )
            );
            setDisplayedNewUsers(filteredUsers.filter(user => !user.userValidated));
            setDisplayedUsers(filteredUsers.filter(user => user.userValidated));
        } else {
            setDisplayedNewUsers(users
                .filter(user => !user.userValidated)
                .sort((a, b) => a.firstName.localeCompare(b.firstName)));

            setDisplayedUsers(users
                .filter(user => user.userValidated)
                .sort((a, b) => a.firstName.localeCompare(b.firstName)));
        }
    };

    return (
        <div>
            <div style={{ marginBottom: "30px", width: "100%", marginTop: "40px" }}>
                <div className={"w-100 flex justify-between"}>
                    <div>
                        {authContext.currentUser?.isSuperAdmin && (
                            <Button type={"primary"} icon={<FontAwesomeIcon icon={faPlus} />} onClick={() => onOpen()}>
                                Ajouter un utilisateur
                            </Button>
                        )}
                        {selectedRowKeys.length > 0 && (
                            <Popconfirm
                                title="Supprimer les utilisateurs"
                                description="Êtes vous sur de vouloir les supprimer ?"
                                okText="Oui"
                                cancelText="Non"
                                onConfirm={() => {handleMultipleElementDelete(rowSelection); setSelectedRowKeys([])}}
                            >
                                <Button
                                    icon={<FontAwesomeIcon icon={faTrash} />}
                                    danger
                                    style={{ marginLeft: 8 }}
                                >
                                    Supprimer les éléments sélectionnés
                                </Button>
                            </Popconfirm>
                        )}
                    </div>
                    <Search placeholder="Recherche"
                            onSearch={handleSearch} allowClear enterButton style={{ width: 250 }}/>
                </div>
            </div>

            <p className={"font-bold text-xl mb-3"}>Nouveaux utilisateurs:</p>
            <Table
                rowKey="_id"
                rowSelection={rowSelection}
                columns={columnsNewUsers}
                dataSource={displayedNewUsers}
                pagination={{ pageSize: 12 }}
                size="middle"
                scroll={{ x: windowDimensions.windowWidth < 900 ? 800 : undefined }}
            />

            <p className={"font-bold text-xl mb-3 mt-6"}>Utilisateurs confirmés:</p>
            <Table
                rowKey="_id"
                rowSelection={rowSelection}
                columns={columns}
                dataSource={displayedUsers}
                pagination={{ pageSize: 12 }}
                size="middle"
                scroll={{ x: windowDimensions.windowWidth < 900 ? 800 : undefined }}
            />

            {isOpen && (
                <div className="modal-backdrop" onClick={() => {onClose(); resetForm();}}>
                    <Modal style={{ padding: "0px", width: "100%" }} hideCloseButton={true} isOpen={isOpen} className={"modal flex flex-col h-max"} onClick={(e) => e.stopPropagation()}>
                        <ModalContent className={"rounded-lg border shadow-sm bg-white max-w-96"}>
                            <ModalHeader className={"font-bold text-2xl mt-3 mb-2 mx-auto"}>
                                {elementId ? elementId === "view" ? "UTILISATEUR" : isNewUser ? "VALIDER L'UTILISATEUR" : "MODIFIER UN UTILISATEUR" : "CRÉER UN UTILISATEUR"}
                            </ModalHeader>
                            <form onSubmit={handleElementSubmit}>
                                <ModalBody>
                                    {isNewUser && <Tag color={"blue"} className={"mx-auto mb-2"}>Veillez à bien sélectionner les entreprises dont cet utilisateur pourra consulter les informations</Tag>}
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Prénom <span className={"text-custom-blue"}>{elementId !== "view" ? "*" : ""}</span></span>
                                        {elementId !== "view" && <Input type="text" placeholder="Prénom" value={newElement.firstName} onChange={(e) => setNewElement({ ...newElement, firstName: e.target.value })} className="w-full rounded-lg"/>}
                                        {elementId === "view" && <Typography.Text>{newElement.firstName}</Typography.Text>}
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Nom <span className={"text-custom-blue"}>{elementId !== "view" ? "*" : ""}</span></span>
                                        {elementId !== "view" && <Input type="text" placeholder="Nom" value={newElement.lastName} onChange={(e) => setNewElement({ ...newElement, lastName: e.target.value })} className="w-full rounded-lg"/>}
                                        {elementId === "view" && <Typography.Text>{newElement.lastName}</Typography.Text>}
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Email <span className={"text-custom-blue"}>{elementId !== "view" ? "*" : ""}</span></span>
                                        {elementId !== "view" && <Input type="text" placeholder="Email" value={newElement.email} onChange={(e) => setNewElement({ ...newElement, email: e.target.value })} className="w-full rounded-lg"/>}
                                        {elementId === "view" && <Typography.Text>{newElement.email}</Typography.Text>}
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Entreprises concernées <span className={"text-custom-blue"}>{elementId !== "view" ? "*" : ""}</span></span>
                                        {elementId !== "view" && <Select value={newElement.concern} onChange={(values) => setNewElement({ ...newElement, concern: values })} mode="multiple" placeholder="Sélectionnez les entreprises concernées" className="w-full rounded-lg">
                                            {concernContext.concernOptions.map((concern) => (
                                                <Select.Option key={concern.name} value={concern.name}>
                                                    {concern.name}
                                                </Select.Option>
                                            ))}
                                        </Select>}
                                        {elementId === "view" &&
                                            <div className={"flex gap-y-2 flex-wrap"}>
                                                {newElement.concern.map((concern, index) => (
                                                    <Tag key={index} className="lowercase">{concern}</Tag>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Administrateur <span className={"text-custom-blue"}>{elementId !== "view" ? "*" : ""}</span></span>
                                        {elementId !== "view" &&
                                            <div className={"flex gap-4"}>
                                                <Typography.Text>L'utilisateur peut-il modifier des éléments dans les entreprises qui le concernent ?</Typography.Text>
                                                <Switch checkedChildren="Oui" unCheckedChildren="Non" className={"min-w-14 ml-auto"} onChange={(e) => setNewElement({ ...newElement, isAdmin: e})} checked={newElement.isAdmin}/>
                                            </div>
                                        }
                                        {elementId === "view" &&
                                            <div className={"flex gap-4"}>
                                                <Typography.Text>L'utilisateur peut-il modifier des éléments dans les entreprises qui le concernent ?</Typography.Text>
                                                <Tag color={newElement.isAdmin ? "green" : "red"} className={"h-max-content ml-auto"}>{newElement.isAdmin ? "Oui" : "Non"}</Tag>
                                            </div>
                                        }
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Super Administrateur <span className={"text-custom-blue"}>{elementId !== "view" ? "*" : ""}</span></span>
                                        {elementId !== "view" &&
                                            <div className={"flex gap-4"}>
                                                <Typography.Text disabled={!newElement.isAdmin}>L'utilisateur peut-il modifier des éléments dans l'onglet "Gestion" ?</Typography.Text>
                                                <Switch disabled={!newElement.isAdmin} checkedChildren="Oui" unCheckedChildren="Non" className={"min-w-14 ml-auto"} onChange={(e) => setNewElement({ ...newElement, isSuperAdmin: e})} checked={newElement.isSuperAdmin}/>
                                            </div>
                                        }
                                        {elementId === "view" &&
                                            <div className={"flex gap-4"}>
                                                <Typography.Text>L'utilisateur peut-il modifier des éléments dans l'onglet "Gestion" ?</Typography.Text>
                                                <Tag color={newElement.isSuperAdmin ? "green" : "red"} className={"h-max-content ml-auto"}>{newElement.isSuperAdmin ? "Oui" : "Non"}</Tag>
                                            </div>
                                        }
                                    </div>
                                    {elementId && <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Email validé</span>
                                        <div className={"flex gap-4"}>
                                            <Typography.Text>L'utilisateur a-t-il validé son email avec le code envoyé par mail ?</Typography.Text>
                                            <Tag color={newElement.isEmailValid ? "green" : "red"} className={`h-max-content ml-auto`}>{newElement.isEmailValid ? "Oui" : "Non"}</Tag>
                                        </div>
                                        {!newElement.isEmailValid && elementId !== "view" && <Button onClick={handleSendVerifyEmail} className={"rounded-lg mt-1"}>Renvoyer un mail de vérification</Button>}
                                    </div>}
                                </ModalBody>
                                <ModalFooter className={"flex flex-col justify-between mt-3"}>
                                    <div className={`flex ${elementId === "view" ? "justify-center" : "justify-between"} gap-2`}>
                                        <Button icon={<CloseOutlined/>} className={"rounded-lg w-44"} onClick={() => {onClose();resetForm();}}>
                                            {elementId === "view" ? "Fermer" : "Annuler"}
                                        </Button>
                                        {elementId && elementId !== "view" && (
                                            <Popconfirm
                                                title="Supprimer l'utilisateur"
                                                description="Êtes vous sur de vouloir le supprimer ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={handleElementDelete}
                                            >
                                                <Button icon={<DeleteOutlined/>} type="primary" danger={true} className={"rounded-lg w-44 mx-auto"}>
                                                    Supprimer
                                                </Button>
                                            </Popconfirm>
                                        )}
                                        {elementId && elementId !== "view" && isNewUser ? (
                                            <Popconfirm
                                                title="Valider l'utilisateur"
                                                description="Êtes-vous sûr de vouloir le valider ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={handleElementSubmit()}
                                            >
                                                <Button
                                                    icon={<CheckOutlined/>}
                                                    type="primary"
                                                    className="rounded-lg w-44"
                                                >
                                                    Valider
                                                </Button>
                                            </Popconfirm>
                                        ) :
                                        elementId && elementId !== "view" && !isNewUser ? (
                                            <Popconfirm
                                                title="Modifier l'utilisateur"
                                                description="Êtes-vous sûr de vouloir le modifier ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={handleElementSubmit}
                                            >
                                                <Button
                                                    icon={<EditOutlined />}
                                                    type="primary"
                                                    className="rounded-lg w-44"
                                                >
                                                    Modifier
                                                </Button>
                                            </Popconfirm>
                                        ) : elementId !== "view" ? (
                                            <Popconfirm
                                                title="Créér l'utilisateur"
                                                description="Êtes-vous sûr de vouloir le créér ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={handleElementSubmit}
                                            >
                                                <Button
                                                    icon={<PlusOutlined />}
                                                    type="primary"
                                                    className="rounded-lg w-44"
                                                >
                                                    Créer
                                                </Button>
                                            </Popconfirm>
                                        ) : null}
                                    </div>
                                </ModalFooter>
                            </form>
                        </ModalContent>
                    </Modal>
                </div>
            )}
        </div>
    );
}