export interface IText {
  itemMyProfile: string;
  itemMyCompany: string;
  itemStatistics: string;
  legalTitle: string;
  privacyPolicyTitle: string;
  contactUsTitle: string;
}

const translations: Map<string, IText> = new Map<string, IText>();

translations.set('fr', {
  "itemMyProfile": "Mon profil",
  "itemMyCompany": "Mon entreprise",
  "itemStatistics": "Statistiques",
  "legalTitle": "Mentions légales",
  "privacyPolicyTitle": "Confidentialité",
  "contactUsTitle": "Nous contacter"
})

translations.set('en', {
  "itemMyProfile": "My profile",
  "itemMyCompany": "My company",
  "itemStatistics": "Statistics",
  "legalTitle": "Legal notice",
  "privacyPolicyTitle": "Privacy policy",
  "contactUsTitle": "Contacts us"
})

translations.set('de', {
  "itemMyProfile": "Mein Profil",
  "itemMyCompany": "Mein Unternehmen",
  "itemStatistics": "Statistiken",
  "legalTitle": "Impressum",
  "privacyPolicyTitle": "Datenschutz-Bestimmungen",
  "contactUsTitle": "Kontaktiere uns"
})

export default translations;
