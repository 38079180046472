import {useNavigate} from "react-router-dom";
import {useTranslation} from "../contexts/TranslationContext";
import React, {useEffect, useRef, useState} from "react";
import {useWindowDimensions} from "../contexts/WindowDimensionsContext";
import {WindowHeight, WindowWidth} from "../utils/windowWidth";
import text, {IText} from '../translations/components/Header';
import {useToastContext} from "../contexts/ToastContext";
import {Navbar, Nav, Dropdown, Offcanvas} from 'react-bootstrap';
import {NavItem} from './NavItem';
import Avatar from './Avatar';
import {faNewspaper} from '@fortawesome/free-solid-svg-icons';
import {faCalendarDays} from '@fortawesome/free-solid-svg-icons';
import {faFile} from '@fortawesome/free-solid-svg-icons';
import {faPhotoFilm} from '@fortawesome/free-solid-svg-icons';
import {faContactCard} from '@fortawesome/free-solid-svg-icons';
import {faGear} from '@fortawesome/free-solid-svg-icons';
import ProfileDropdownMenu from "./ProfileDropdownMenu";
import NavbarBrand from "./NavbarBrand";
import {Container} from "react-bootstrap";
import {useAuth} from "../contexts/AuthContext";
import "../css/constants.css"
import {animateScroll, scroller} from 'react-scroll';

interface IProps {
    currentPage?: string;
    return?: any
}

const Header = (props: IProps) => {
    const language = useTranslation().currentLanguage;
    const texts = (text.get(language) as IText)
    const navigate = useNavigate();
    const translate = useTranslation();
    const toastContext = useToastContext()
    const windowDimensions = useWindowDimensions();
    const authContext = useAuth();
    const avatarUserName: string = authContext.currentUser?.firstName[0] as string + authContext.currentUser?.lastName[0] as string | "";
    const [toggle, setToggle] = useState(false);
    const [isToggleVisible, setIsToggleVisible] = useState(false);
    const toggleButtonRef = useRef(null);

    useEffect(() => {
        function handleResize() {
            windowDimensions.setWindowWidth(WindowWidth())
            windowDimensions.setWindowHeight(WindowHeight())
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        toastContext.closePrecedentToasts()
    }, [window.location.href]);

    useEffect(() => {
        if (!isToggleVisible) {
            document.body.classList.remove('toggle-open');
        }
    }, [isToggleVisible]);

    useEffect(() => {
        const toggleButton = toggleButtonRef.current;

        if (toggleButton) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    setIsToggleVisible(entry.intersectionRatio > 0);
                },
                {
                    threshold: 0.5,
                }
            );
            observer.observe(toggleButton);
            return () => observer.disconnect();
        }
    }, []);

    const handleNavbarToggle = (isToggled: boolean) => {
        if (isToggled && window.scrollY > 0) {
            scroller.scrollTo('top', {duration: 0, smooth: false})
        }
        setToggle(isToggled);
    };

    return (
        <Navbar className="navbar-top fixed-top header-z-index px-5" expand={"xl"} expanded={toggle} onToggle={handleNavbarToggle}>
            <Container fluid className={`${isToggleVisible && toggle ? "mt-2" : ""}`}>
                <NavbarBrand/>
                <Navbar.Collapse className={isToggleVisible && toggle?"mx-5 mt-2":"mx-5"}>
                    <Nav className="navbar-nav navbar-nav-top">
                        <NavItem icon={faNewspaper} link="/">
                            {texts.news}
                        </NavItem>
                        <NavItem icon={faFile} link="/documents">
                            {texts.documents}
                        </NavItem>
                        {windowDimensions.wWCheck(1000) && <NavItem icon={faCalendarDays} link="/calendar">
                            {texts.calendar}
                        </NavItem>}
                        <NavItem icon={faPhotoFilm} link="/media">
                            {texts.media}
                        </NavItem>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="mx-5">
                    <Nav style={{marginLeft: "auto"}} className="navbar-nav navbar-nav-top">
                        <NavItem icon={faContactCard} link="/contact">
                            {texts.contact}
                        </NavItem>
                        {/*<NavItem icon={faLink} link="/facilis">*/}
                        {/*    {texts.facilis}*/}
                        {/*</NavItem>*/}
                        {authContext.currentUser?.isSuperAdmin && <NavItem icon={faGear} link="/manage">
                            {texts.manage}
                        </NavItem>}
                    </Nav>
                </Navbar.Collapse>
                <div className="navbar-nav navbar-nav-icons flex-row my-3 ms-auto">
                    <Nav.Item>
                        <Dropdown autoClose={true} className="h-100">
                            <Dropdown.Toggle
                                className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
                                variant=""
                            >
                                <Avatar size="l" className="text-16" variant="name">
                                    {avatarUserName}
                                </Avatar>
                            </Dropdown.Toggle>
                            <ProfileDropdownMenu user={authContext.currentUser}/>
                        </Dropdown>
                    </Nav.Item>
                    <Nav.Item>
                        <Navbar.Toggle ref={toggleButtonRef} className="navbar-toggler-right mx-3" aria-controls="basic-navbar-nav"/>
                    </Nav.Item>
                </div>
            </Container>
        </Navbar>
    )
}

export default Header;
