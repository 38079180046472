import {Route, Routes} from "react-router-dom";
import {useEffect} from "react";
import {useAuth} from "./contexts/AuthContext";
import {useCounter} from "./contexts/CounterContext";
import InDevelopment from "./pages/General/InDeveloppement";
import NotFound from "./pages/General/NotFound";
import Counter from "./pages/General/Counter";
import Login from "./pages/Auth/Login";
import Calendar from "./pages/Calendar/Calendar";
import counterRoutes from "./api/controllers/counter"
import userRoutes from "./api/controllers/user";
import 'react-toastify/dist/ReactToastify.css';
import News from "./pages/News/News";
import Documents from "./pages/Documents/Documents";
import Main from "./pages/General/Main";
import Manage from "./pages/Manage/Manage";
import Profile from "./pages/Auth/Profile";
import Media from "./pages/Media/Media";
import Contacts from "./pages/Contacts/Contacts"
import ContactForm from "./pages/Auth/ContactForm";
import Register from "./pages/Auth/Register";
import ResetPassword from "./pages/Auth/ResetPassword";
import VerifyEmail from "./pages/Auth/VerifyEmail";
import axios from "axios";

export function MyRoutes() {
    const counterContext = useCounter()
    const authContext = useAuth()

    const getCounter = (name: string) => {
        counterRoutes.getByName(name).then((res) => {
            if (name === "totalnumber")
                counterContext.setTotalCounter((window.location.pathname.toLocaleLowerCase() !== "/counter") ? res.data.response[0].number+1 : res.data.response[0].number)
            if (name === "daynumber")
                counterContext.setDayCounter((window.location.pathname.toLocaleLowerCase() !== "/counter") ? res.data.response[0].number+1 : res.data.response[0].number)
            if (name === "resetnumber")
                counterContext.setResetCounter((window.location.pathname.toLocaleLowerCase() !== "/counter") ? res.data.response[0].number+1 : res.data.response[0].number)
        }).catch((err) => {
            if (err.response.status === 404) {
                counterRoutes.create({name: name, number: 0}).then(() => {
                    getCounter(name)
                })
            } else
                console.error(err)
        })
    }

    const increaseCounter = (name: string) => {
        counterRoutes.getByName(name).then((res) => {
            counterRoutes.updateByName(name, {name: name, number: (res.data.response[0].number+1)}).then((res2) => {

            })
        }).catch((err) => {console.error(err)})
    }

    const manageCounters = async () => {
        await getCounter("totalnumber")
        await getCounter("daynumber")
        await getCounter("resetnumber")
        if (window.location.pathname.toLocaleLowerCase() !== "/counter" && !authContext.isLoggedIn()) {
            increaseCounter("totalnumber")
            increaseCounter("daynumber")
            increaseCounter("resetnumber")
        }
    }

    const checkAdminUsersExists = () => {
        userRoutes.getByEmail("forster.frederic@gmail.com").catch((err) => {
            if (err.response.status === 404)
                // userRoutes.create({firstName: "Frédéric", lastName: "Forster", email: "forster.frederic@gmail.com", password: "Frederic137*", concern: ["KIMMEL FINANCES", "KIMMEL LOGISTIK", "KIMMEL SERVICES", "KIMMEL LAVAGE", "KIMMEL BETON", "CTVL DE THAL"], isAdmin: true, isSuperAdmin: true, isEmailValid: true}).then(() => {
                userRoutes.create({firstName: "Frédéric", lastName: "Forster", email: "forster.frederic@gmail.com", password: "Frederic137*", concern: ["KIMMEL FINANCES", "KIMMEL LOGISTIK", "KIMMEL SERVICES", "KIMMEL LAVAGE", "KIMMEL BETON", "CTVL DE THAL"], userValidated: true, isAdmin: true, isSuperAdmin: false, isEmailValid: true}).then(() => {
                    console.log("Mandatory admin user \"forster.frederic@gmail.com\" created")
                })
            else
                console.error(err)
        }).catch((err) => {console.error(err)})
        userRoutes.getByEmail("mathieu.schmitt@team-kimmel.com").catch((err) => {
            if (err.response.status === 404)
                userRoutes.create({firstName: "Mathieu", lastName: "Schmitt", email: "mathieu.schmitt@team-kimmel.com", password: "password", concern: ["KIMMEL FINANCES", "KIMMEL SERVICES", "KIMMEL LAVAGE", "KIMMEL BETON", "KIMMEL TRANSPORTS", "KIMMEL LOGISTIK", "CTVL DE THAL", "SUPL TACHY", "LAVILLE TRANSPORTS", "SUPL SERVICES", "SUPL PNEUMATIK", "SLK LOGISTIC", "STOCKEDIS PLUS", "WEBER TRANSPORTS"], userValidated: true, isAdmin: true, isSuperAdmin: true, isEmailValid: true}).then(() => {
                    console.log("Mandatory admin user \"mathieu.schmitt@team-kimmel.com\" created")
                })
            else
                console.error(err)
        }).catch((err) => {console.error(err)})
        userRoutes.getByEmail("user.test@team-kimmel.com").catch((err) => {
            if (err.response.status === 404)
                userRoutes.create({firstName: "User", lastName: "Test", email: "user.test@team-kimmel.com", password: "password", concern: ["KIMMEL FINANCES", "KIMMEL LOGISTIK", "KIMMEL SERVICES", "KIMMEL LAVAGE", "KIMMEL BETON", "CTVL DE THAL"], userValidated: true, isAdmin: false, isSuperAdmin: false, isEmailValid: false}).then(() => {
                    console.log("Mandatory test user \"user.test@team-kimmel.com\" created | password: password")
                })
            else
                console.error(err)
        }).catch((err) => {console.error(err)})
        userRoutes.getByEmail("user2.test@team-kimmel.com").catch((err) => {
            if (err.response.status === 404)
                userRoutes.create({firstName: "User2", lastName: "Test", email: "user2.test@team-kimmel.com", password: "password", concern: ["CTVL DE THAL", "SUPL TACHY", "LAVILLE TRANSPORTS", "SUPL SERVICES", "SUPL PNEUMATIK", "SLK LOGISTIC", "STOCKEDIS PLUS", "WEBER TRANSPORTS"], userValidated: true, isAdmin: false, isSuperAdmin: false, isEmailValid: false}).then(() => {
                    console.log("Mandatory test user 2 \"user2.test@team-kimmel.com\" created | password: password")
                })
            else
                console.error(err)
        }).catch((err) => {console.error(err)})
    }

    const checkConcernsExists = () => {
        const elements = [
            "CTVL DE THAL",
            "SUPL TACHY",
            "KIMMEL FINANCES",
            "KIMMEL LOGISTIK",
            "KIMMEL SERVICES",
            "KIMMEL LAVAGE",
            "KIMMEL TRANSPORTS",
            "LAVILLE TRANSPORTS",
            "SUPL SERVICES",
            "SUPL PNEUMATIK",
            "SLK LOGISTIC",
            "STOCKEDIS PLUS",
            "WEBER TRANSPORTS",
            "KIMMEL BETON"
        ]

        elements.map((thisElement) => {
            axios.get(`http://localhost:3001/api/concern/name/${thisElement}`, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                if (res.status === 200 && res.data.success === false)
                    axios.post(`http://localhost:3001/api/concern`, {name: thisElement} ,{
                        headers: { "Content-Type": "application/json" },
                    }).then(() => console.log("Concern '"+thisElement+"' created")).catch((err) => console.error(err))
            }).catch((err) => {console.error(err)})
        })
    }

    const checkDocumentsFoldersExists = () => {
        const elements = [
            "DOSSIER 1",
            "DOSSIER 2",
            "DOSSIER 3",
            "DOSSIER 4",
            "DOSSIER 5",
            "DOSSIER 6",
            "DOSSIER 7",
            "DOSSIER 8",
            "DOSSIER 9",
            "DOSSIER 10",
            "DOSSIER 11",
            "DOSSIER 12",
            "DOSSIER 13",
            "DOSSIER 14",
            "DOSSIER 15",
            "DOSSIER 16",
            "DOSSIER 17",
            "DOSSIER 18",
        ]

        elements.map((thisElement) => {
            axios.get(`http://localhost:3001/api/documents-folders/name/${thisElement}`, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                if (res.status === 200 && res.data.success === false)
                    axios.post(`http://localhost:3001/api/documents-folders`, {name: thisElement} ,{
                        headers: { "Content-Type": "application/json" },
                    }).then(() => console.log("Document Folder '"+thisElement+"' created")).catch((err) => console.error(err))
            }).catch((err) => {console.error(err)})
        })
    }

    useEffect(() => {
        manageCounters()
        checkAdminUsersExists()
        checkDocumentsFoldersExists()
        checkConcernsExists()
    }, [])

    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/reset-password" element={<ResetPassword/>}/>
            <Route path="/verify-email" element={<VerifyEmail/>}/>
            <Route path="/" element={<Main/>}>
                <Route path="/" element={<News/>}/>
                <Route path="/calendar" element={<Calendar/>}/>
                <Route path="/documents" element={<Documents/>}/>
                <Route path="/media" element={<Media/>}/>
                <Route path="/contact" element={<Contacts/>}/>
                <Route path="/manage" element={<Manage/>}/>
                <Route path="/counter" element={<Counter/>}/>
                <Route path="/profile" element={<Profile/>}/>
                <Route path="/contact-us" element={<ContactForm/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Route>
            <Route path="/in-development" element={<InDevelopment/>}/>
        </Routes>
    );
}

export default MyRoutes;
