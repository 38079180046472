import {Flex} from 'rebass';
import {useWindowDimensions} from "../../contexts/WindowDimensionsContext";
import MyText from "../../components/basics/MyText";

const InDevelopment = () => {
    const windowDimensions = useWindowDimensions();

    return (
        <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"} padding={25}
              height={windowDimensions.windowHeight}>
            <MyText value={"Notre site est en cours de développement"}
                    style={{fontSize: "var(--GiantFontSize)", textAlign: "center"}}/>
            <MyText value={"Nous serons ravi de vous revoir sur notre site internet une fois celui-ci terminé"}
                    style={{marginTop: "25px", fontSize: "var(--MediumFontSize)", textAlign: "center"}}/>
        </Flex>
    )
}

export default InDevelopment;
