import React from 'react';
import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate()

  return (
      <Result
          status="404"
          title="404"
          subTitle="Oups, la page que vous recherchez n'existe pas."
          extra={<Button onClick={() => navigate("/")} type="primary">Retour à la page d'accueil</Button>}
      />
  );
}

export default NotFound;
