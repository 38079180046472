export interface IText {
    all: string;
}

const translations: Map<string, IText> = new Map<string, IText>();

translations.set('fr', {
    "all": "Tous"
})

translations.set('en', {
    "all": "All"
})

translations.set('de', {
    "all": "Alle"
})

export default translations;
