import {Outlet} from "react-router"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import "../../css/constants.css"

export default function Main() {
    return (
        <div style={{background: "var(--BackgroundInside)"}} className="flex flex-col min-h-screen">
            <Header/>
            <main className="content">
                <Outlet/>
            </main>
                <Footer/>
        </div>
    )
}