import TranslationProvider from "./contexts/TranslationContext";
import WindowDimensionsProvider from "./contexts/WindowDimensionsContext";
import CounterProvider from "./contexts/CounterContext";
import AuthProvider from "./contexts/AuthContext";
import ToastProvider from "./contexts/ToastContext";
import ConcernProvider from "./contexts/ConcernContext";
import { CustomProvider } from 'rsuite';
import frFr from 'rsuite/locales/fr_FR';
import MyRoutes from "./MyRoutes";
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'rsuite/dist/rsuite.css'
import './css/constants.css'
import {Toaster} from "sonner";

export function App() {
    return (
        <BrowserRouter>
            <CustomProvider locale={frFr} disableRipple={true}>
                <TranslationProvider>
                    <ConcernProvider>
                        <AuthProvider>
                            <WindowDimensionsProvider>
                                <ToastProvider>
                                    <CounterProvider>
                                    {/*    <Toaster richColors/>*/}
                                    {/*    <ToastContainer theme={"colored"}/>*/}
                                        <MyRoutes/>
                                    </CounterProvider>
                                </ToastProvider>
                            </WindowDimensionsProvider>
                        </AuthProvider>
                    </ConcernProvider>
                </TranslationProvider>
            </CustomProvider>
        </BrowserRouter>
    );
}

export default App;
