export interface IText {
    invalidCredentials: string;
    anErrorOccurred: string;
    resetPasswordEmailSent: string;
    passwordChanged: string;
    userCreating: string;
    compagnyCreated: string;
    failedToAskAccount: string;
    accountAsked: string;
}

const translations: Map<string, IText> = new Map<string, IText>();

translations.set('fr', {
    "invalidCredentials": "Adresse e-mail ou mot de passe invalide",
    "anErrorOccurred": "Une erreur est survenue",
    "resetPasswordEmailSent": "Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse e-mail",
    "passwordChanged": "Votre mot de passe a été modifié",
    "userCreating": "Votre compte est en cours de création",
    "compagnyCreated": "Votre entreprise a été créée",
    "failedToAskAccount": "Une erreur est survenue lors de la demande de création de compte",
    "accountAsked": "Votre demande de création de compte a été envoyée, vous recevrez un mail lorsqu'elle sera traitée"
})

translations.set('en', {
    "invalidCredentials": "Invalid email address or password",
    "anErrorOccurred": "An error occurred",
    "resetPasswordEmailSent": "A reset password email has been sent to your email address",
    "passwordChanged": "Your password has been changed",
    "userCreating": "Your account is being created",
    "compagnyCreated": "Your compagny is ",
    "failedToAskAccount": "An error occurred while requesting account creation",
    "accountAsked": "Your account creation request has been sent, you will receive an email when it is processed"
})

translations.set('de', {
    "invalidCredentials": "Ungültige E-Mail-Adresse oder ungültiges Passwort",
    "anErrorOccurred": "Es ist ein Fehler aufgetreten",
    "resetPasswordEmailSent": "Ein E-Mail zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet",
    "passwordChanged": "Ihr Passwort wurde geändert",
    "userCreating": "Ihr Konto wird erstellt",
    "compagnyCreated": "Ihr Unternehmen wurde erstellt",
    "failedToAskAccount": "Beim Anfordern der Kontenerstellung ist ein Fehler aufgetreten",
    "accountAsked": "Ihre Anfrage zur Kontoerstellung wurde gesendet. Sie erhalten eine E-Mail, wenn sie bearbeitet wird"
})

export default translations;
