import "../../css/constants.css";
import {Flex} from "rebass";
import MyText from "../../components/basics/MyText";
import React, {useEffect, useState} from "react";
import PageTitle from "../../components/PageTitle";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import {Loading} from "../../components/Loading";
import { scroller } from 'react-scroll';
import {useWindowDimensions} from "../../contexts/WindowDimensionsContext";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure} from "@nextui-org/react";
import {Button, Input, message, Select, Switch, Divider, Popconfirm} from "antd";
import axios from "axios";
import {Dropzone} from "../../components/dropzone";
import ImageViewer from "react-simple-image-viewer";
import {CloseOutlined, EditOutlined, PlusOutlined, DownloadOutlined, CopyOutlined, EyeOutlined, FolderOutlined, DeleteOutlined, FileOutlined} from "@ant-design/icons";
const { TextArea } = Input;

export default function Documents() {
    const navigate = useNavigate()
    const authContext = useAuth();
    const windowDimensions = useWindowDimensions();
    const [documents, setDocuments] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState("");
    const [folders, setFolders] = useState([]);
    const [filter, setFilter] = useState("");
    const [isPictureViewerOpen, setIsPictureViewerOpen] = useState(false);
    const [pictureViewerPicture, setPictureViewerPicture] = useState("");
    const [loading, setLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [file, setFile] = useState(null);
    const [elementId, setElementId] = useState(null);
    const [newElement, setNewElement] = useState({_id: "", title: "", concern: [], folder: "", content: "", url: [], file: ""});
    const [highlighted, setHighlighted] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const idToSearch = searchParams.get("id");
    const [numberOfLinks, setNumberOfLinks] = useState( 0);
    const [sendEmail, setSendEmail] = useState(true);

    useEffect(() => {
        fetchElements()
    }, [])

    useEffect(() => {
        if (idToSearch) {
            const highlightedElement = documents.find(element => element._id === idToSearch);
            if (highlightedElement) {
                setHighlighted(idToSearch);
                setSelectedFolder(highlightedElement.folder);
                setSearchParams({});
                setTimeout(() => {
                    scroller.scrollTo(idToSearch, {
                        duration: 200,
                        smooth: true,
                        offset: -100,
                    });
                }, 100);
            }
        }
    }, [idToSearch, documents]);

    const fetchElements = async () => {
        setLoading(true);
        try {
            await axios.get(`http://localhost:3001/api/documents-folders`, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                if (res.data.success !== false) {
                    setFolders(res.data.response.sort((a, b) => {
                        const dateA = new Date(a.updatedAt || 0).getTime();
                        const dateB = new Date(b.updatedAt || 0).getTime();
                        return dateB - dateA;
                    }))
                    if (selectedFolder === "")
                        setSelectedFolder(res.data.response.sort((a, b) => {
                            const dateA = new Date(a.updatedAt || 0).getTime();
                            const dateB = new Date(b.updatedAt || 0).getTime();
                            return dateB - dateA;
                        })[0]?.name)
                } else if (res.data.success === false && res.status === 200) {
                    setFolders([])
                }
            }).catch((err) => {console.error(err)})
            const response = await axios.get(`http://localhost:3001/api/documents/`);
            if (response.status === 200 && response.data.response.length > 0) {
                const changed = response.data.response
                    .map((item) => {
                        const fileUrl = item.file
                            ? `${"http://localhost:3001/api".replace("/api", "")}/${item.file.replace('/root/apps/intrakimmel/dest/mern/server/', '')}`
                            : "";
                        return { ...item, file: fileUrl };
                    })
                setDocuments(changed.sort((a, b) => {
                    const dateA = new Date(a.updatedAt || 0).getTime();
                    const dateB = new Date(b.updatedAt || 0).getTime();
                    return dateB - dateA;
                }));
            } else if (response.status === 404) {
                setDocuments([]);
            }
        } catch (error) {
            setDocuments([]);
            console.error('Error fetching documents:', error);
        } finally {
            setLoading(false);
        }
    };

    const createStyledHtmlContent = (response) => {
        const documentId = elementId ? newElement._id : response.data.element._id || "noid";
        const formattedUrls = newElement.url?.map(link =>
            `<tr>
            <td style="padding: 6px 0;">
                <a href="${link.link}" style="color: #2980b9; text-decoration: underline;">${link.title}</a>
            </td>
        </tr>`
        ).join('') || "";

        return `
            <html>
            <head>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        background-color: #F5F7FA;
                        padding: 50px;
                        margin: 0;
                        color: #333;
                    }
                    h1 {
                        color: #000000;
                        font-size: 28px;
                        margin-bottom: 25px;
                        font-weight: bold;
                    }
                    .container {
                        max-width: 600px;
                        margin: 0 auto;
                        background: #FFFFFF;
                        padding: 30px;
                        border-radius: 8px;
                        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
                    }
                    .footer {
                        font-size: 12px;
                        color: #aaa;
                        text-align: center;
                        margin-top: 35px;
                    }
                    .button {
                        background-color: #1777FF;
                        color: white;
                        padding: 12px 35px;
                        border-radius: 5px;
                        text-decoration: none;
                        font-size: 16px;
                        font-weight: bold;
                        display: inline-block;
                        width: 250px;
                        margin-top: 15px;
                    }
                    .button:hover {
                        background-color: #166fed;
                    }
                    .divider {
                        border-top: 1px solid #dadada;
                        margin: 35px 0;
                    }
                    .content {
                         white-space: pre-wrap;
                         text-align: justify; 
                    }
                    .associate-links {
                        font-size: 18px;
                        font-weight: bold;
                    }
                    .document-file {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                    
                    .document-icon svg {
                        width: 24px;
                        height: 24px;
                        fill: #0073e6;
                    }
                    
                    .file-name {
                        font-size: 16px;
                        color: #333333;
                        font-weight: bold;
                    }
                </style>
            </head>
            <body>
                <div class="container">
                    <h1>${newElement.title}</h1>
                    <p class="content">${newElement.content || ''}</p>
                    
                    <div class="divider"></div>
            
                    <div class="document-file">
                        <span class="document-icon">
                            <svg viewBox="0 0 28 28" fill="#0073e6" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.4797 4.15793C14.6095 0.833113 19.0267 -0.132589 22.3457 2.00098C25.6648 4.13454 26.6288 8.55944 24.4989 11.8843L22.6498 10.6956C24.1243 8.39379 23.4569 5.3304 21.1591 3.85332C18.8614 2.37624 15.8033 3.0448 14.3288 5.3466L12.4797 4.15793Z" fill="#0073e6"/>
                                <path d="M14.3278 5.34752L5.1311 19.7042C4.14959 21.2384 4.5946 23.2789 6.12591 24.263C7.65789 25.2475 9.69685 24.8018 10.68 23.2674L13.0534 19.5629L13.0519 19.5619L18.9849 10.3002L18.9863 10.3012C19.4777 9.53391 19.2553 8.51284 18.4894 8.0205C17.7234 7.52814 16.7041 7.751 16.2126 8.51826L16.2111 8.51733L11.5 16.001C11.2118 16.4509 10.6138 16.5814 10.1643 16.2925L9.94284 16.1501C9.49339 15.8612 9.36268 15.2622 9.65088 14.8123L14.3621 7.32857L14.3635 7.3295C15.5104 5.53929 17.8888 5.01934 19.676 6.16816C21.4631 7.317 21.9822 9.69964 20.8354 11.4899L20.8339 11.489L18.4613 15.1927L18.4632 15.1939L12.5297 24.4564C10.891 27.0135 7.49232 27.756 4.93909 26.1152C2.38578 24.4743 1.64432 21.071 3.28299 18.5136L12.4787 4.15885L14.3278 5.34752Z" fill="#0073e6"/>
                                <path d="M15.4516 23.7222C15.0022 23.4333 14.8715 22.8343 15.1597 22.3844L22.6473 10.6957L24.4965 11.8844L17.0088 23.5731C16.7206 24.023 16.1226 24.1535 15.6731 23.8646L15.4516 23.7222Z" fill="#0073e6"/>
                            </svg>
                        </span>
                        <p class="file-name">
                            ${response.data.element.file.replace(/^.*?-/, '')}
                        </p>
                    </div>
            
                    <div class="divider"></div>
                    
                    ${newElement.url.length > 0 ? `<p class="associate-links">Liens associés:</p>` : ''}
            
                    ${formattedUrls ?
                    `<table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                            ${formattedUrls}
                    </table>`
                    : ''}
            
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                            <td align="center" style="padding-top: 25px;">
                                <a href="http://localhost:3000/documents?id=${documentId}" class="button" style="color: white">
                                    Ouvrir le document
                                </a>
                            </td>
                        </tr>
                    </table>
            
                    <div class="footer">
                        <p>Merci de votre attention !</p>
                        <p>La team Kimmel</p>
                    </div>
                </div>
            </body>
            </html>`;
    };

    const handleElementSubmit = async (event) => {
        event?.preventDefault();
        if (!newElement.concern || newElement.concern.length === 0) {
            message.error("Veuillez sélectionner au moins une entreprise.");
            return;
        }
        if (!newElement.title) {
            message.error("Veuillez entrer un titre pour le document.");
            return;
        }
        if (!newElement.folder) {
            message.error("Veuillez sélectionner le dossier dans lequel intégrer le document.");
            return;
        }
        if (!file) {
            message.error("Veuillez sélectionner un fichier de document.");
            return;
        }

        const formData = new FormData();
        Object.keys(newElement).forEach((key) => {
            if (Array.isArray(newElement[key])) {
                if (key === "url") {
                    formData.append(key, JSON.stringify(newElement[key]));
                } else {
                    newElement[key].forEach((item) => formData.append(key, item));
                }
            } else {
                formData.append(key, newElement[key]);
            }
        });

        if (file) {
            formData.append("file", file);
        }

        setLoading(true);
        try {
            let response;

            if (elementId) {
                response = await axios.put(`http://localhost:3001/api/documents/id/${elementId}`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                message.success("Document modifié avec succès.");
            } else {
                response = await axios.post(`http://localhost:3001/api/documents/`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                message.success("Nouveau document créé avec succès.");
            }
            onClose();
            resetForm();
            fetchElements();
            if (sendEmail) {
                const concernedUsers = await axios.post(`http://localhost:3001/api/user/concern`, {concern: newElement.concern}, {
                    headers: {"Content-Type": "application/json"},
                });
                await axios.post("http://localhost:3001/api/send-email/", {to: concernedUsers.data, subject: elementId ? "Le document '" + documents.find((e) => e._id === newElement._id)?.title + "' a été modifié" : "Nouveau document disponible: " + newElement.title, html: createStyledHtmlContent(response)}, {
                    headers: {"Content-Type": "application/json"},
                });
                message.success("Email envoyé aux concernés.");
            }
        } catch (error) {
            message.error("Erreur lors de la soumission du document.");
            console.error("Error submitting document:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleElementDelete = async () => {
        try {
            await axios.delete(`http://localhost:3001/api/documents/id/${elementId}`);
            message.success("Le document a été supprimé.");
            resetForm();
            fetchElements()
        } catch (error) {
            message.error("Erreur lors de la suppression du document.");
            console.error("Error deleting document:", error);
        }
    }

    const resetForm = () => {
        setElementId(null);
        setNewElement({
            _id: "",
            title: "",
            concern: [],
            folder: "",
            content: "",
            url: [],
            file: ""
        });
        setNumberOfLinks(0)
        setFile(null);
        setSendEmail(true);
    };

    function formatTimeDifference(timestampRaw) {
        const timestamp = new Date(timestampRaw);
        const now = new Date();
        const timeDifference = now.getTime() - timestamp.getTime();
        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        if (days > 0) {
            return `${days} jour${days === 1 ? "" : "s"}`;
        } else if (hours > 0) {
            return `${hours} heure${hours === 1 ? "" : "s"}`;
        } else {
            return `${minutes} minute${minutes === 1 ? "" : "s"}`;
        }
    }

    const handleTitleChange = (index, value) => {
        setNewElement(prevElement => {
            const updatedUrls = [...prevElement.url];

            if (index < updatedUrls.length) {
                updatedUrls[index].title = value;
            } else {
                updatedUrls.push({ title: value, link: "" });
            }

            return {
                ...prevElement,
                url: updatedUrls,
            };
        });
    };

    const handleLinkChange = (index, value) => {
        setNewElement(prevElement => {
            const updatedUrls = [...prevElement.url];

            if (index < updatedUrls.length) {
                updatedUrls[index].link = value;
            } else {
                updatedUrls.push({ title: "", link: value });
            }

            return {
                ...prevElement,
                url: updatedUrls,
            };
        });
    };

    const handleRemoveLink = (indexToRemove) => {
        setNumberOfLinks(prevNumberOfLinks => prevNumberOfLinks - 1);

        setNewElement(prevElement => {
            const updatedUrls = prevElement.url.filter((_, index) => index !== indexToRemove);

            return {
                ...prevElement,
                url: updatedUrls,
            };
        });
    };

    const MyCard = (props) => {
        const [thisHighlighted, setThisHighlighted] = useState(false);

        useEffect(() => {
            if (highlighted === props.data._id) {
                setThisHighlighted(true);
                const timeoutId = setTimeout(() => {
                    setThisHighlighted(false);
                    setHighlighted("")
                }, 4750)
            }
        }, []);

        const handleDownload = async (filename) => {
            if (props.data.file) {
                try {
                    const response = await fetch(props.data.file, {
                        method: 'GET',
                        headers: {
                        }
                    });

                    if (!response.ok) {
                        throw new Error("Failed to download file");
                    }

                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.download = filename || 'downloaded-file';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    window.URL.revokeObjectURL(url);
                } catch (error) {
                    console.error("Error downloading file:", error);
                }
            }
        };

        return (
            <Card id={props.data._id} style={{height: "100%"}} className={`identifiable-element ${thisHighlighted ? 'highlighted' : ''}`}>
                <Card.Header>
                    <Card.Title style={{ fontSize: "28px" }}>{props.data.title}</Card.Title>
                </Card.Header>

                <div className={"pt-4 h-100 px-4 pb-2"}>
                    <Flex flexWrap={"wrap"} justifyContent="space-between">
                        <Flex flexDirection={"column"} style={{flex: "3", minWidth: "300px", marginRight: props.data.file ? "10px" : ""}}>
                            <Card.Text style={{textAlign: "justify", whiteSpace: "pre-wrap"}}>{props.data.content}</Card.Text>
                        </Flex>
                    </Flex>
                </div>

                <div className={"pt-4 h-100 px-4 pb-4"} style={{display: "flex", alignItems: "center", gap: "10px"}}>
                        <span style={{width: '24px', height: "24px", fill: "#0073e6"}}>
                            <svg viewBox="0 0 28 28" fill="#0073e6" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.4797 4.15793C14.6095 0.833113 19.0267 -0.132589 22.3457 2.00098C25.6648 4.13454 26.6288 8.55944 24.4989 11.8843L22.6498 10.6956C24.1243 8.39379 23.4569 5.3304 21.1591 3.85332C18.8614 2.37624 15.8033 3.0448 14.3288 5.3466L12.4797 4.15793Z" fill="#0073e6"/>
                                <path d="M14.3278 5.34752L5.1311 19.7042C4.14959 21.2384 4.5946 23.2789 6.12591 24.263C7.65789 25.2475 9.69685 24.8018 10.68 23.2674L13.0534 19.5629L13.0519 19.5619L18.9849 10.3002L18.9863 10.3012C19.4777 9.53391 19.2553 8.51284 18.4894 8.0205C17.7234 7.52814 16.7041 7.751 16.2126 8.51826L16.2111 8.51733L11.5 16.001C11.2118 16.4509 10.6138 16.5814 10.1643 16.2925L9.94284 16.1501C9.49339 15.8612 9.36268 15.2622 9.65088 14.8123L14.3621 7.32857L14.3635 7.3295C15.5104 5.53929 17.8888 5.01934 19.676 6.16816C21.4631 7.317 21.9822 9.69964 20.8354 11.4899L20.8339 11.489L18.4613 15.1927L18.4632 15.1939L12.5297 24.4564C10.891 27.0135 7.49232 27.756 4.93909 26.1152C2.38578 24.4743 1.64432 21.071 3.28299 18.5136L12.4787 4.15885L14.3278 5.34752Z" fill="#0073e6"/>
                                <path d="M15.4516 23.7222C15.0022 23.4333 14.8715 22.8343 15.1597 22.3844L22.6473 10.6957L24.4965 11.8844L17.0088 23.5731C16.7206 24.023 16.1226 24.1535 15.6731 23.8646L15.4516 23.7222Z" fill="#0073e6"/>
                            </svg>
                        </span>
                    <p style={{fontSize: "14px", color: "#333333", fontWeight: "bold"}}>
                        {props.data.file.replace(/^.*?-/, '')}
                    </p>
                </div>

                {props.data.url?.length > 0 && (
                    <Card.Body className="pt-0 w-100">
                        <Flex flexDirection="column" style={{ gap: "8px", maxHeight: "150px", overflowY: "auto" }}>
                            <p style={{ marginBottom: "3px", fontSize: "14px", fontWeight: "bold"}} className={"text-lg"}>Liens associés:</p>
                            {props.data.url.map((link, index) => (
                                <Flex
                                    key={index}
                                    alignItems="center"
                                    style={{
                                        flexWrap: "wrap",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Card.Link
                                        onClick={() =>
                                            link.link.startsWith("https://") ? window.open(link.link, "_blank", "noopener,noreferrer") : navigate(link.link)
                                        }
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                            textUnderlineOffset: "3px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "95%",
                                        }}
                                        title={link.link}
                                    >
                                        {link.title}
                                    </Card.Link>
                                </Flex>
                            ))}
                        </Flex>
                    </Card.Body>
                )}

                <Card.Footer className={"mt-auto py-3"}>
                    <Flex justifyContent={"space-between"} flexDirection={"column"}>
                        <Flex className={"px-4 mb-1"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"}>
                            <Flex width={"100%"} style={{gap: "10px"}}>
                                <Button icon={<EyeOutlined/>} className={"my-2"} style={{width: "100%"}} onClick={() => {setPictureViewerPicture(props.data.file); setIsPictureViewerOpen(true)}}>Coup d'oeil</Button><Button icon={<DownloadOutlined/>} className={"my-2"} style={{width: "100%"}} onClick={() => handleDownload(props.data.file.split('-').slice(1).join('-'))}>Télécharger</Button>
                            </Flex>
                            <Flex flexDirection={"column"} className={"me-3"}>
                                <Card.Text className={"text-muted"} style={{fontSize: "12px", width: "max-content", whiteSpace: "pre-wrap", wordBreak: "break-all", overflowWrap: "break-word"}}>{props.data.file?.name}</Card.Text>
                            </Flex>
                        </Flex>
                        <Flex className={"px-4 mb-2"} width={"100%"} style={{gap: "10px"}}>
                            {authContext.currentUser?.isAdmin &&
                                <Button icon={<CopyOutlined/>} style={{width: "100%"}} className={"mb-2"} onMouseDown={(e) => {e.preventDefault();}} onBlur={(e) => e.preventDefault()} onClick={() => {navigator.clipboard.writeText("/documents?id="+props.data._id).then(() => {message.success("Le lien à été copié" + " ("+props.data.title+")")}).catch((err) => {console.error(err)})}}>
                                    Copier le lien
                                </Button>
                            }
                            {authContext.currentUser?.isAdmin &&
                                <Button icon={<EditOutlined />} style={{ width: "max-content" }} className="mb-2 w-100" onClick={() => {setNewElement({ ...props.data, url: props.data.url || [] });setNewElement({ ...props.data, folder: props.data.folder || "" });setNumberOfLinks(props.data.url ? props.data.url.length : 0);setElementId(props.data._id);setFile(props.data.file);onOpen();}}>
                                    Modifier
                                </Button>
                            }
                        </Flex>
                        <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "5px" }}>
                            <MyText className="text-muted mx-auto" style={{ fontSize: "var(--MiniFontSize)" }} value={"Entreprises concernées: "}/>
                            {props.data.concern.map((item, idx) => (
                                <MyText key={item} className="text-muted mx-auto lowercase" style={{ fontSize: "var(--MiniFontSize)" }} value={item === "All" ? "" : item + (idx !== props.data.concern.length - 1 ? ", " : "")}/>
                            ))}
                        </div>
                        {props.data.updatedAt &&
                            <small className="text-muted mt-auto mx-auto pe-3">{"Dernière mise à jour il y a " + formatTimeDifference(props.data.updatedAt)}</small>
                        }
                    </Flex>
                </Card.Footer>
            </Card>
        )
    }

    const elementFilter = (element, userConcern, filter) => {
        const concernFilter = filter === "" || element.concern.includes(filter);

        return (
            (authContext.currentUser?.isAdmin || element.concern.some(r => userConcern?.includes(r))) && element.folder === selectedFolder && concernFilter
        );
    };

    const renderByConcern = () => {
        const userConcernSet = new Set();
        let hasDisplayedElements = false;

        authContext.currentUser?.concern.forEach((element) => {
            userConcernSet.add(element);
        });

        const userConcerns = Array.from(userConcernSet);

        const elementFilter = (element, userConcern, filter) => {
            const matchesUserConcern = authContext.currentUser?.isAdmin ||
                element.concern.some(r => userConcern.includes(r));
            const matchesFilter = (filter === "" || element.concern.includes(filter)) && element.folder === selectedFolder;

            return matchesUserConcern && matchesFilter;
        };

        const renderDividerSection = (filter) => {
            const filtered = documents.filter(thisElement => elementFilter(thisElement, userConcerns, filter));

            if (filtered.length > 0) {
                hasDisplayedElements = true;

                return (
                    <div style={{ width: "100%" }} key={filter}>
                        <Divider className={"my-6"} style={{borderColor: "var(--Blue", color: "var(--Blue", fontWeight: "bold" }}>{filter + " (" + filtered.length + ")"}</Divider>
                        <Row xxl={3} style={{ width: "100%" }} className="g-4 mt-0 mb-1">
                            {filtered.map((thisElement, elementIndex) => (
                                <Col style={{ maxWidth: windowDimensions.wWCheck(900) ? "50%" : "" }} key={elementIndex}>
                                    <MyCard data={thisElement} key={elementIndex} />
                                </Col>
                            ))}
                        </Row>
                    </div>
                );
            }
            return null;
        };

        const sections = filter === ""
            ? userConcerns.map((concern) => renderDividerSection(concern))
            : renderDividerSection(filter);

        return (
            <>
                {sections}
                {!hasDisplayedElements && (
                    <div style={{ width: "100%" }}>
                        <Flex justifyContent={"center"} className={"text-muted"} style={{ width: "100%", marginTop: "100px" }} key="no-news">
                            <MyText value={"Rien à afficher pour le moment"} style={{ fontSize: "var(--MediumFontSize)" }} />
                        </Flex>
                    </div>
                )}
            </>
        );
    };

    return (
        <div style={{marginBottom: "auto"}}>
            <PageTitle title={"Documents"}>
                {authContext.currentUser?.isAdmin &&
                    <Button onClick={onOpen} type={"primary"} icon={<PlusOutlined/>} style={{ height: "min-content", marginTop: "auto", marginBottom: "auto" }} className="me-2">
                        Ajouter un document
                    </Button>
                }
                {authContext.currentUser?.concern && authContext.currentUser?.concern.length > 1 &&
                    <Select onChange={(values) => values === undefined ? setFilter("") : setFilter(values)} className={"w-56 mr-5 ml-auto"} placeholder="Filtrer l'entreprise" allowClear={true}>
                        {authContext.currentUser?.concern.sort().map((concern) => (
                            <Select.Option key={concern} value={concern}>{concern}</Select.Option>
                        ))}
                    </Select>
                }
            </PageTitle>

            <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", marginTop: "30px" }}>
                <ButtonGroup style={{display: "flex", flexWrap: "wrap", gap: "10px", maxWidth: "100%", justifyContent: "flex-start"}}>
                    {!loading && folders && folders.sort().map(thisElement => (
                        <Button icon={<FolderOutlined/>} key={thisElement._id} onClick={() => setSelectedFolder(thisElement.name)} style={{background: selectedFolder === thisElement.name ? "#1777FF" : "#EBEFF5", color: selectedFolder === thisElement.name ? "white" : "black", width: "fit-content", whiteSpace: "nowrap", flex: "0 1 auto"}}>
                            {thisElement.name}
                        </Button>
                    ))}
                </ButtonGroup>
            </div>

            <Flex justifyContent={"center"} alignItems={"center"} flexWrap={"wrap"}>
                {loading && <Loading />}
                <Row xxl={3} style={{ width: "100%" }} className="g-4 mb-5">
                    {!loading && (documents.length === 0 ?
                        <Flex justifyContent={"center"} className={"text-muted"} style={{ width: "100%", marginTop: "150px" }}>
                            <MyText value={"Rien à afficher pour le moment"} style={{ fontSize: "var(--MediumFontSize)" }} />
                        </Flex> :
                        renderByConcern()
                    )}
                </Row>
            </Flex>

            {isOpen && (
                <div className="modal-backdrop" onClick={() => {onClose();resetForm();}}>
                    <Modal style={{ padding: "0px", width: "100%" }} hideCloseButton={true} isOpen={isOpen} className={"modal flex flex-col h-max"} onClick={(e) => e.stopPropagation()}>
                        <ModalContent className={"rounded-lg border shadow-sm bg-white max-w-96"}>
                            <ModalHeader className={"font-bold text-2xl mt-3 mb-2 mx-auto"}>
                                {elementId ? "MODIFIER LE DOCUMENT" : "CRÉER UN DOCUMENT"}
                            </ModalHeader>
                            <form onSubmit={handleElementSubmit}>
                                <ModalBody>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Entreprises concernée(s) <span className={"text-custom-blue"}>*</span></span>
                                        <Select value={newElement.concern} onChange={(values) => setNewElement({ ...newElement, concern: values })} mode="multiple" placeholder="Sélectionnez les entreprises concernées" className="w-full rounded-lg">
                                            {authContext.currentUser?.concern.map((concern) => (
                                                <Select.Option key={concern} value={concern}>
                                                    {concern}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Titre du Document <span className={"text-custom-blue"}>*</span></span>
                                        <Input type="text" placeholder="Titre du document" value={newElement.title} onChange={(e) => setNewElement({ ...newElement, title: e.target.value })} className="w-full rounded-lg"/>
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Description du contenu</span>
                                        <TextArea autoSize type="text" placeholder="Description du contenu" value={newElement.content} onChange={(e) => setNewElement({ ...newElement, content: e.target.value })} className="w-full rounded-lg"/>
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Assigner à un dossier <span className={"text-custom-blue"}>*</span></span>
                                        <Select value={newElement.folder ? newElement.folder : null} onChange={(value) => setNewElement({ ...newElement, folder: value })} placeholder="Sélectionnez le dossier concerné" className="w-full rounded-lg">
                                            {folders.map((folder) => (
                                                <Select.Option key={folder._id} value={folder.name}>
                                                    {folder.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Document <span className={"text-custom-blue"}>*</span></span>
                                        <Dropzone onChange={(file) => setFile(file)} initialFile={file} fileType={"document"}/>
                                    </div>
                                    {Array.from(Array(numberOfLinks).keys()).map((link, index) => (
                                        <div className="w-full">
                                            <span className="block text-gray-500 font-medium mb-1">Lien {index+1}</span>
                                            <Flex justifyContent={"space-between"} key={index}>
                                                <Input value={newElement.url[index]?.title || ""} onChange={(e) => handleTitleChange(index, e.target.value)} placeholder={`Titre ${index + 1}`} style={{ width: "100%", marginRight: "3px" }}/>
                                                <Input value={newElement.url[index]?.link || ""} onChange={(e) => handleLinkChange(index, e.target.value)} placeholder={`Url ${index + 1}`} style={{ width: "100%", marginLeft: "3px" }}/>
                                                <Button icon={<CloseOutlined />} style={{height: "min-content", marginTop: "auto", marginBottom: "auto", marginLeft: "6px", paddingLeft: "4px", paddingRight: "4px", color: "red",}} onClick={() => handleRemoveLink(index)}/>
                                            </Flex>
                                        </div>
                                    ))}
                                    <Button icon={<PlusOutlined />} type={"text"} disabled={numberOfLinks >= 5} style={{ width: "100%" }} className="me-2" onClick={() => {if (numberOfLinks < 5) setNumberOfLinks(numberOfLinks + 1);}}>
                                        Ajouter un lien
                                    </Button>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Envoyer un email aux concernés</span>
                                        <Switch onClick={() => setSendEmail(!sendEmail)} checked={sendEmail} checkedChildren="Oui" unCheckedChildren="Non" className={"min-w-14 ml-auto"}/>
                                    </div>
                                </ModalBody>
                                <ModalFooter className={"flex flex-col justify-between mt-3"}>
                                    <div className={"flex justify-between gap-2"}>
                                        <Button icon={<CloseOutlined/>} className={"rounded-lg w-44"} onClick={() => {onClose();resetForm();}}>
                                            Annuler
                                        </Button>
                                        {elementId && (
                                            <Popconfirm
                                                title="Supprimer le document"
                                                description="Êtes vous sur de vouloir le supprimer ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={() => {onClose(); resetForm();handleElementDelete()}}
                                            >
                                                <Button icon={<DeleteOutlined/>} type="primary" danger={true} className={"rounded-lg w-44 mx-auto"}>
                                                    Supprimer
                                                </Button>
                                            </Popconfirm>
                                        )}
                                        {elementId ? (
                                                <Popconfirm
                                                    title="Modifier le document"
                                                    description="Êtes-vous sûr de vouloir le modifier ?"
                                                    okText="Oui"
                                                    cancelText="Non"
                                                    onConfirm={handleElementSubmit}
                                                >
                                                    <Button
                                                        icon={<EditOutlined />}
                                                        type="primary"
                                                        className="rounded-lg w-44"
                                                    >
                                                        Modifier
                                                    </Button>
                                                </Popconfirm>
                                            ) :
                                            <Popconfirm
                                                title="Créer le document"
                                                description="Êtes-vous sûr de vouloir le créer ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={handleElementSubmit}
                                            >
                                                <Button
                                                    icon={<PlusOutlined />}
                                                    type="primary"
                                                    className="rounded-lg w-44"
                                                >
                                                    Créer
                                                </Button>
                                            </Popconfirm>
                                        }
                                    </div>
                                </ModalFooter>
                            </form>
                        </ModalContent>
                    </Modal>
                </div>
            )}

            {loading && <p className="mt-6 text-center">Loading...</p>}

            {isPictureViewerOpen && (
                <div style={{ position: "relative", zIndex: "9999" }}>
                    <ImageViewer src={[pictureViewerPicture]} onClose={() => setIsPictureViewerOpen(false)} disableScroll={false} backgroundStyle={{backgroundColor: "rgba(0,0,0,0.9)"}} closeOnClickOutside={true}/>
                </div>
            )}
        </div>
    );
};