import {IUser} from '../models/user'
import api from "../api";

export const login : any = (email: string, password: string) => api.post(`/user/login`, {email, password})
export const create = (payload: IUser) => api.post(`/user`, payload)
export const updateById = (id: any, payload: IUser) => api.put(`/user/id/${id}`, payload)
export const deleteAll = () => api.delete(`/user`)
export const deleteById = (id: any) => api.delete(`/user/id/${id}`)
export const getAll = () => api.get(`/user`)
export const getById = (id: any) => api.get(`/user/id/${id}`)
export const getByEmail = (email: any) => api.get(`/user/email/${email}`)

const userRoutes = {
    login,
    create,
    updateById,
    deleteAll,
    deleteById,
    getAll,
    getById,
    getByEmail,
}

export default userRoutes