export const WindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
}

export const WindowWidth = () => {
  return WindowDimensions().width
}

export const WindowHeight = () => {
  return WindowDimensions().height
}
