import { Navbar } from 'react-bootstrap';
import Logo from "../assets/pictures/logo.png";
import { Link } from 'react-router-dom';

const NavbarBrand = () => {
  return (
    <>
      <div className="navbar-logo">
        <Navbar.Brand
          as={Link}
          to="/"
          className="me-1 me-sm-3 horizontal"
        >
          <div className="d-flex align-items-center">
              <img src={Logo} alt="phoenix" width={120} style={{pointerEvents: "none"}}/>
          </div>
        </Navbar.Brand>
      </div>
    </>
  );
};

export default NavbarBrand;
