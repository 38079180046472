import {createContext, useContext, useState} from "react";

const TranslationContext = createContext(undefined as any);

type ProviderTranslationT = {
  currentLanguage: string,
  setCurrentLanguage: (language: string) => void
  availableLanguages: string[],
}

export const useTranslation = () :ProviderTranslationT => {
  return useContext(TranslationContext);
}

interface IProps {
  children: JSX.Element[] | JSX.Element;
}

const TranslationProvider = ({children}: IProps) => {
  const [currentLanguage, setCurrentLanguage] = useState("fr");
  const availableLanguages = ["fr", "en", "de"];

  const value = {
    currentLanguage,
    setCurrentLanguage,
    availableLanguages
  }

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
}

export default TranslationProvider;
