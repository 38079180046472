import "../css/constants.css"
import {Flex} from "rebass";
import {useWindowDimensions} from "../contexts/WindowDimensionsContext";

interface IProps {
    title: string;
    subtitle?: string;
    children?: React.ReactNode;
}

export default function PageTitle(props: IProps) {
    const windowDimensions = useWindowDimensions()

    return (
        <Flex flexDirection={windowDimensions.wWCheck(700) ? "row" : "column"} alignItems={"center"} style={{marginLeft: "10px", color: "var(--Black)"}}>
            <Flex justifyContent={"center"}>
                <h2 className="mb-0">
                    {props.title}
                    {props.subtitle && (
                        <span className="fw-normal text-700 ms-3">
                            {props.subtitle}
                        </span>
                    )}
                </h2>
            </Flex>
            <Flex flexWrap={"wrap"} width={"100%"} marginLeft={windowDimensions.wWCheck(700) ? "40px" : ""} marginTop={windowDimensions.wWCheck(700) ? "" : "20px"} justifyContent={windowDimensions.wWCheck(700) ? "space-between" : "center"}>
                {props.children &&
                    props.children
                }
            </Flex>
        </Flex>
    );
}
