import {ICounter} from '../models/counter'
import api from "../api";

export const create = (payload: ICounter) => api.post(`/counter`, payload)
export const updateById = (id: any, payload: ICounter) => api.put(`/counter/id/${id}`, payload)
export const updateByName = (name: any, payload: ICounter) => api.put(`/counter/name/${name}`, payload)
export const deleteAll = () => api.delete(`/counter`)
export const deleteById = (id: any) => api.delete(`/counter/id/${id}`)
export const deleteByName = (name: any) => api.delete(`/counter/name/${name}`)
export const getAll = () => api.get(`/counter`)
export const getById = (id: any) => api.get(`/counter/id/${id}`)
export const getByName = (name: any) => api.get(`/counter/name/${name}`)

const counterRoutes = {
    create,
    updateById,
    updateByName,
    deleteAll,
    deleteById,
    deleteByName,
    getAll,
    getById,
    getByName,
}

export default counterRoutes