export interface IText {
    pageTitle: string
    new: string
    modalAddTitle: string
    modalModifyTitle: string
    btnLabelCancel: string
    btnLabelCreate: string
    btnLabelDelete: string
    btnLabelModify: string
    screenSizeTooSmall: string
    all: string
}

const translations: Map<string, IText> = new Map<string, IText>();

translations.set('fr', {
    all: "TOUTES LES ENTREPRISES",
    pageTitle: "Calendrier",
    new: "Ajouter un événement",
    modalAddTitle: "Créer un événement",
    modalModifyTitle: "Modifier un événement",
    btnLabelCancel: "Annuler",
    btnLabelCreate: "Créer",
    btnLabelDelete: "Supprimer",
    btnLabelModify: "Mettre à jour",
    screenSizeTooSmall: "Taille d'écran trop petite, vous avez besoin d'au moins 1000px"
})

translations.set('en', {
    all: "ALL COMPANIES",
    pageTitle: "Calendar",
    new: "Add an event",
    modalAddTitle: "Create an event",
    modalModifyTitle: "Modify an event",
    btnLabelCancel: "Cancel",
    btnLabelCreate: "Create",
    btnLabelDelete: "Delete",
    btnLabelModify: "Update",
    screenSizeTooSmall: "Screen size too small, you need minimum 1000px"
})

translations.set('de', {
    all: "ALLE UNTERNEHMEN",
    pageTitle: "Kalender",
    new: "Fügen Sie ein Ereignis hinzu",
    modalAddTitle: "Erstellen Sie ein Ereignis",
    modalModifyTitle: "Ändern Sie ein Ereignis",
    btnLabelCancel: "Stornieren",
    btnLabelCreate: "Erstellen",
    btnLabelDelete: "Löschen",
    btnLabelModify: "Aktualisieren",
    screenSizeTooSmall: "Bildschirmgröße zu klein, Sie benötigen mindestens 1000px"
})
export default translations;