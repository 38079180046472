import {createContext, useContext, useState} from "react";
import {toast} from "react-toastify";
const ToastContext = createContext(undefined as any);

type ProviderToastT = {
  closePrecedentToasts: () => void
  toastSuccess: (text: string, props: any) => void,
  toastError: (text: string, props: any) => void,
  toastWarning: (text: string, props: any) => void,
  toastLoading: (text: string, props: any) => void,
  toastInfo: (text: string, props: any) => void,
  toastDefault: (text: string, props: any) => void,
  toastEmailVerification: (props: any) => void,
  toastUpdate: (props: any) => void,
  toastClose: () => void,
  toastEmailClose: () => void,
  theToast: any,
  theToastId: string
}

export const useToastContext = () : ProviderToastT => {
  return useContext(ToastContext);
}

interface IProps {
  children: JSX.Element[] | JSX.Element;
}

const ToastProvider = ({children}: IProps) => {
  const [theToastAvailable, setTheToastAvailable] = useState(false)
  let firstClose = false
  const theToastId = "id"
  const theEmailToastId = "idEmail"
  const theToast = toast
  const theEmailToast = toast

  const closePrecedentToasts = () => {
      theToast.dismiss();
      theEmailToast.dismiss();
  }

  const onCloseEvent = () => {
      if (firstClose)
          setTheToastAvailable(false)
      else
        firstClose = true
  }

  const toastDefault = (text: string, props: any) => {
    setTheToastAvailable(true)
    props.toastId = theToastId
    props.position = "top-center"
    props.pauseOnFocusLoss = false
    props.onClose = () => {onCloseEvent()}
    return theToast(text, props);
  }

  const toastSuccess = (text: string, props: any) => {
    setTheToastAvailable(true)
    props.toastId = theToastId
    props.position = "top-center"
    props.pauseOnFocusLoss = false
    props.onClose = () => {onCloseEvent()}
    return theToast.success(text, props);
  }

  const toastError = (text: string, props: any) => {
    setTheToastAvailable(true)
    props.toastId = theToastId
    props.position = "top-center"
    props.pauseOnFocusLoss = false
    props.onClose = () => {onCloseEvent()}
    return theToast.error(text, props);
  }

  const toastWarning = (text: string, props: any) => {
    setTheToastAvailable(true)
    props.toastId = theToastId
    props.position = "top-center"
    props.pauseOnFocusLoss = false
    props.onClose = () => {onCloseEvent()}
    return theToast.warning(text, props);
  }

  const toastInfo = (text: string, props: any) => {
    setTheToastAvailable(true)
    props.toastId = theToastId
    props.position = "top-center"
    props.pauseOnFocusLoss = false
    props.onClose = () => {onCloseEvent()}
    return theToast.info(text, props);
  }

  const toastLoading = (text: string, props: any) => {
    setTheToastAvailable(true)
    props.toastId = theToastId
    props.isLoading = true
    props.position = "top-center"
    props.pauseOnFocusLoss = false
    props.onClose = () => {onCloseEvent()}
    return theToast.loading(text, props);
  }

  const toastEmailVerification = (props: any) => {
    props.toastId = theEmailToastId
    props.position = "top-center"
    props.type = "warning"
    return toastDefault(props.render, props)
  }

  const toastUpdate = (props: any) => {
    props.toastId = theToastId
    props.position = "top-center"
    props.pauseOnFocusLoss = false
    if (theToastAvailable) {
        firstClose = true
        props.onClose = () => {onCloseEvent()}
        return theToast.update(theToastId, props);
    } else {
        return toastDefault(props.render, props)
    }
  }

  const toastClose = () => {
    return theToast.dismiss(theToastId);
  }

  const toastEmailClose = () => {
    return theEmailToast.dismiss(theEmailToastId);
  }

  const value = {
    closePrecedentToasts,
    toastDefault,
    toastSuccess,
    toastError,
    toastWarning,
    toastInfo,
    toastLoading,
    toastEmailVerification,
    toastUpdate,
    toastClose,
    toastEmailClose,
    setTheToastAvailable,
    theToast,
    theToastId,
  }


  return (
    <ToastContext.Provider value={value}>
      {children}
    </ToastContext.Provider>
  );
}

export default ToastProvider;
